import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid, Paper, Typography, InputBase, Chip, Tooltip, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import { useCompletion } from "ai/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Sentry from '@sentry/react';
import { Masonry } from "react-plock";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import analytics from '../util/analytics';
import Skeleton from '@material-ui/lab/Skeleton';
import { IDEAS, QUESTIONS } from '../util/chat';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

const sampleN = (arr, n) => [...arr].sort(() => 0.5 - Math.random()).slice(0, n);

function removeDuplicateThumbnails(questions) {
  const seenArticles = new Set();

  let ideas = questions
  if(questions.questions){
    ideas = questions.questions
  }
  if(questions.titles){
    ideas = questions.titles
  }

  return ideas.map(item => {
    if (item.news) {
      const articleId = item.news.articleId;
      if (seenArticles.has(articleId)) {
        // This is a duplicate, nullify the image_url
        return {
          ...item,
          news: {
            ...item.news,
            imageUrl: null
          }
        };
      } else {
        // This is the first occurrence, add to seen set
        seenArticles.add(articleId);
        return item;
      }
    }
    // If there's no news, return the item as is
    return item;
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
    // backgroundColor: th,
  },
  searchBar: {
    // marginLeft: '50px',
    // marginRight: '50px',
    padding: '2px 8px',
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIcon: {
    padding: 3,
  },
  categoriesContainer: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper
  },
  cardContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: theme.spacing(2),
    minWidth: 280,
    borderRadius: '10px',
  },
  newsCard: {
    backgroundColor: theme.palette.type === 'light'? '#EAF4FF' : '#0e122e',
    paddingTop: theme.spacing(4)
  },
  newsLabel:{
    color: '#2583E8',
    borderColor: '#2583E8',
    borderRadius: '5px',
    fontSize: '11px',
  },
  infoButton: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    
  },
  newsInfoIcon: {
    color: '#2583E8',
    padding: '2px'
  },
  personalCard: {
    backgroundColor: '#E8B724'
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
  
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
  },
}));

const cardStyles = [
  { bgcolor: '#dfefff', color: '#000000', fontSize: '23px', fontFamily: 'Montserrat' },
  { bgcolor: '#ededed', color: '#000000', fontSize: '18px', fontFamily: 'Montserrat' },
  { bgcolor: '#fffaea', color: '#2d2d2d', fontSize: '31px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#ffefff', color: '#2d2d2d', fontSize: '19px', fontFamily: '"Courier New", monospace' },
  { bgcolor: '#eaf4ff', color: '#2d2d2d', fontSize: '35px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#e8eaf6', color: '#2d2d2d', fontSize: '21px', fontFamily: '"Montserrat", sans-serif' },
];

const ContentCard = ({ title, news, hideImage = false, onClick }) => {
  const classes = useStyles();
  const style = cardStyles[Math.floor(Math.random() * cardStyles.length)];
  const [showImage, setShowImage] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth < 200 || naturalHeight < 200) {
      setShowImage(false);
    }
  };

  const handleOpenDialog = (e) => {
    setOpenDialog(true);
    e.stopPropagation()
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const cardStyle = {fontFamily: style.fontFamily, fontSize: style.fontSize, backgroundColor: style.bgcolor, color: style.color};

  return (
    <Paper elevation={0} key={title} className={`${classes.card} ${news ? classes.newsCard : ''}`} 
      // style={news ? {} : cardStyle}
    >
      <div className={`${classes.cardContent}`}>
        <Typography onClick={onClick} variant="body1"
          // style={news ? {} : cardStyle}
        
        >{title}</Typography>
      </div>
      {news && news.imageUrl && showImage &&
        <img src={news.imageUrl} alt={title} className={classes.cardImage} onLoad={handleImageLoad} onError={() => setShowImage(false)} />
      }
      {news && (
        <>
          
            <div className={classes.infoButton}>
              <Chip label="TRENDING NEWS" size='small' color='link' variant="outlined" className={classes.newsLabel} />
              <Tooltip title="More Info">
              <IconButton  size="small" onClick={handleOpenDialog}>
                <InfoIcon className={classes.newsInfoIcon} />
              </IconButton>
              </Tooltip>
            </div>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <Typography style={{opacity: 0.7, position: 'absolute', top: '20px', left: '24px'}}>
              Source: {news.sourceId} ({news.domainUrl}), {formatDate(news.pubDate)}
            </Typography>
            <DialogTitle style={{paddingTop: '50px', paddingBottom: 0}}>{news.engTitle}</DialogTitle>
            <DialogContent>
              {news.imageUrl && (
                  <Link href={news.imageUrl} target="_blank" rel="noopener noreferrer">
                    <img src={news.imageUrl} alt={news.engTitle} className={classes.dialogImage} />
                  </Link>
                )}
                
                <Typography gutterBottom>
                  {news.summary}
                </Typography>
                
                
            </DialogContent>
            <DialogActions>
              <Button 
                  component={Link}
                  href={news.articleUrl}
                  target="_blank"
                  color="inherit"
                  rel="noopener noreferrer"
                >
                Read Full Article
              </Button>
              <Button onClick={handleCloseDialog} color="primary" variant="contained">
                Close
              </Button>
              
            </DialogActions>
          </Dialog>
        </>
      )}
    </Paper>
  );
};



const IdeaDashboard = ({type='titles'}) => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [localIdeas, setLocalIdeas] = useState([]);
  const [localQuestions, setLocalQuestions] = useState([]);
  const [activeSearchTerm, setActiveSearchTerm] = useState(() => {
    const defaultVal = {titles: '', questions: ''}
    if (typeof window !== 'undefined') {
      return { [type]: localStorage.getItem(`lastActiveSearchTerm_${type}`) } || defaultVal;
    }
    return defaultVal
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);

  const queryClient = useQueryClient();


  const { data: generatedIdeas = [], isFetching, error } = useQuery({
    queryKey: ['ideas', type, activeSearchTerm[type]],
    queryFn: () => fetchIdeas(activeSearchTerm[type], auth?.user?.auth.currentUser.getIdToken()),
    enabled: !!activeSearchTerm[type] && !!auth?.user,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 24000 * 60 * 60, // Cache for 24 hour
    initialData: () => {
      const cachedData = queryClient.getQueryData(['ideas', type, activeSearchTerm[type]]);
      return cachedData || undefined;
    },
  });

  const generateIdeasMutation = useMutation({
    mutationFn: (keyword) => fetchIdeas(keyword, auth?.user?.auth.currentUser.getIdToken()),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['ideas', type, variables], data);
      setActiveSearchTerm((prev) => ({ ...prev, [type]: variables}));
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${type}`, variables);
      }
    },
  });

  const fetchIdeas = async (keyword, token) => {
    console.log("IT'S BEING CALLED ", keyword)
    const IdToken = await token
    const response = await fetch('/api/completion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${IdToken}`,
      },
      body: 
        type === 'titles' ? 
          JSON.stringify({ prompt: keyword, task: 'bulk-generate-titles', questionAmount: 30, user: auth?.user })
          :
          JSON.stringify({ prompt: keyword, task: 'bulk-generate-ideas', news: keyword, questionAmount: 20, user: auth?.user }),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch ideas');
    }
  
    let data = await response.json();
    if (type === 'questions') {
      return shuffleArray(removeDuplicateThumbnails(data));
    } else if (type === 'titles') {
      if(data.titles){
        data = data.titles
      }
      data = Array.isArray(data) ? data : [];
      data = data.map(x => ({question: x, news: null}))
    }
    return shuffleArray(data);
  }

  const handleCategoryToggle = (category) => {
    analytics.track('clickIdeaCategory', {category: category})
    setSearchTerm(category);
    const cachedData = queryClient.getQueryData(['ideas', type, category]);
    if (!cachedData) {
      generateIdeasMutation.mutate(category);
    } else {
      setActiveSearchTerm((prev) => ({ ...prev, [type]: category}));
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${type}`, category);
      }
    }
  };

  const handleIdeaClick = (idea) => {
    analytics.track(`click_${type}_card`, {idea: idea})
    const searchParams = new URLSearchParams(router.location.search);
    searchParams.set('title', idea);
    const newSearchString = searchParams.toString();
    router.push(`/notes/new?${newSearchString}`);
  };

  const handleSearchSubmit = (event) => {
    if (event) event.preventDefault();
    analytics.track(`search_${type}`, {searchTerm})
    if (generateIdeasMutation.isLoading) return;
    const cachedData = queryClient.getQueryData(['ideas', type, searchTerm]);
    if (!cachedData) {
      generateIdeasMutation.mutate(searchTerm);
    } else {
      setActiveSearchTerm((prev) => ({ ...prev, [type]: searchTerm}));
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${type}`, searchTerm);
      }
    }
  };

  useEffect(() => {
    if (activeSearchTerm[type] && !generatedIdeas.length && !isFetching) {
      const cachedData = queryClient.getQueryData(['ideas', type, activeSearchTerm[type]]);
      if (!cachedData) {
        generateIdeasMutation.mutate(activeSearchTerm[type]);
      }
    }
  }, [activeSearchTerm[type], type, generatedIdeas, isFetching]);

  useEffect(() => {
    let _categories = []

    if (auth.user) {
      if(auth.user?.linkedinProfile?.country_full_name){
        _categories.push(auth.user.linkedinProfile.country_full_name)

        if (activeSearchTerm[type] === 'world news') {
          setActiveSearchTerm((prev) => ({ ...prev, [type]: auth.user.linkedinProfile.country_full_name}));
        }
      }
      if (auth.user.tags) {
       _categories = _categories.concat(sampleN(auth.user.tags, Math.min(auth.user.tags.length, 3)))
      } 
      else if (auth.user.topics) {
        _categories = _categories.concat(sampleN(auth.user.topics.map(x => x.substring(2)), Math.min(auth.user.topics.length, 2)))
      }
    }
    if (type === 'titles') {
      _categories = ['Learnings', 'Celebrate', 'Tips', 'Myths', 'Tools curation', 'Expertise', 'Mistakes', 'Experience', 'Stories']
    }
    setCategories(_categories)
    setSearchTerm(activeSearchTerm[type])
    setLocalQuestions(shuffleArray(auth.user.topics.slice(0,3).flatMap(topic => QUESTIONS[topic] ?? []).slice(0,50)));
    setLocalIdeas(shuffleArray(auth.user.topics.flatMap(topic => IDEAS[topic] ?? []).slice(0,50)));
  }, [auth.user, type]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // questions or ideas
  let items = []
  if (auth.user) {
    if (type === 'questions') {
      const userQuestions = auth.user?.questions ?? []
      items = [...(generatedIdeas ?? []), ...userQuestions.map(x => ({ question: x, news: null })), ...localQuestions]
    }
    else {
      let userIdeas = auth.user?.ideas ?? []
      if (userIdeas.titles) {
        userIdeas = userIdeas.titles
      }
      items = [...(generatedIdeas ?? []),  ...userIdeas.map(x => ({ question: x, news: null })), ...localIdeas]
    } 
  }

  return (
    <div className={classes.root}>
      <Paper elevation={0} component="form" className={classes.searchBar} onSubmit={handleSearchSubmit}>
        {(generateIdeasMutation.isLoading || isFetching) ? <CircularProgress className={classes.searchIcon}/> : <SearchIcon className={classes.searchIcon} />}
        <InputBase
          className={classes.input}
          placeholder="content creation"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button type="submit" disabled={generateIdeasMutation.isLoading}>
          Search
        </Button>
      </Paper>
      
      {/* <Button onClick={handleRefresh} disabled={generateIdeasMutation.isLoading || !activeSearchTerm[type]}>
        Refresh Ideas
      </Button> */}
      
      <div className={classes.categoriesContainer}>
        {auth?.user && categories.map((category) => (
          <Chip
            key={category}
            label={category}
            disabled={generateIdeasMutation.isLoading}
            onClick={() => handleCategoryToggle(category)}
            color={category === activeSearchTerm[type] ? "primary" : "default"}
            className={classes.chip}
          />
        ))}
      </div>

      <Grid container spacing={4} className={classes.cardContainer}>
        {auth?.user && (
          <Masonry
            config={{
              columns: [1, 2, 3],
              gap: [1, 12, 6],
              media: [640, 768, 1024],
            }}
            items={items}
            render={(item, index) => (
              <ContentCard
                title={item.question || item}
                news={item?.news}
                onClick={() => handleIdeaClick(item.question || item)}
              />
            )}
          />
        )}
      </Grid>
      {!generatedIdeas && 
        <div className={classes.cardContainer}>
           {new Array(9).fill(0).map((_, index) => (
          <Skeleton variant="rect" width={'25%'} height={200} className={classes.card} />
           ))}
        </div>
      }
    </div>
  );
};

export default IdeaDashboard;