import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";

function HeroSection(props) {
  const isMobile = props.isMobile

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader mb={0}
            title={props.title}
            smallTitle={props.smallTitle}
            subtitle={props.subtitle}
            size={isMobile ? 4 : 2}
          />
          { props.buttonText && 
            <Button
            component={Link}
            to={props.buttonPath}
            variant="contained"
            size="large"
            color={props.buttonColor}
            > <strong>{props.buttonText}</strong>
            </Button>
          }

          

          <Box mb={isMobile ? 0 : 0} mt={isMobile ? 5 : 5}>
            { props.youtube &&
              <iframe width={isMobile ? 360 : 992} height={isMobile ? 215 : 558} src="https://www.youtube.com/embed/6gybgZcKoYE?si=QadM3rAVeT9QXNkw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            }
            { props.image &&
              <img 
              src={props.image} 
              style={{
                maxWidth: isMobile ? '100%' : '100%', // Full width on mobile
                maxHeight: isMobile ? '40vh' : '70vh', // Responsive height
              }}
            />
            }
            
          </Box>
          
          
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection;
