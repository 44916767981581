import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Player } from '@lottiefiles/react-lottie-player';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  progressBar: {
    width: '80%',
    maxWidth: '500px',
    marginTop: theme.spacing(2),
  },
}));

const LoadingBackdrop = () => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Function to simulate progress
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 90) {
          clearInterval(timer);
          return 95;
        }
        const diff = 100 / 15; // Divide by 7 to complete the progress in 7 seconds
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Player
        autoplay
        loop
        src="https://lottie.host/e8707118-4f8e-45e6-a33e-e2e9b0a3e25e/wmsvKnSRKm.json" // Example Lottie animation URL
        style={{ height: '300px', width: '300px' }}
      />
      <Typography>Tailoring Cleve for you...</Typography>
      <LinearProgress variant="determinate" value={progress} className={classes.progressBar} />
      {/* Optionally, you can add text or additional styling below the Lottie player */}
    </Backdrop>
  );
};

export default LoadingBackdrop;