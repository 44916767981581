import React from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "./../util/router.js";

function Meta(props) {
  const { children, ...customPageMeta } = props;
  const router = useRouter();

  if(router.query && router.query.via) {
    window.localStorage.setItem('referrer', router.query.via);
  }

  // Meta values that are the same across all pages
  const globalMeta = {
    // Site name
    siteName: "Cleve",
    // Your production domain (example: https://myapp.com)
    domain: "https://www.cleve.ai",
    // Your Twitter handle (example: @divjoy)
    twitterHandle: "",
  };

  // Default meta values for current page (override with props)
  const defaultPageMeta = {
    // Page title
    title: "AI assistant helping creators scale their online presence - Cleve",
    // Page description
    description: "Go from idea to post faster than ever.",
    // Social share image (create this file in /public/images/)
    image: "/cleve1-desktop.webp",
    // Page type (see https://ogp.me/#types)
    type: "website",
  };

  // Construct meta object from global, default, and custom meta
  const meta = { ...globalMeta, ...defaultPageMeta, ...customPageMeta };

  // Note: Each tag should have a unique `key` so that they are de-deduped if other
  // `Meta` components are rendered on the same page or within nested components.
  // prettier-ignore
  return (
    <Helmet>
      { !window.promotekit &&
      <script key='promotekit-script' async src="https://cdn.promotekit.com/promotekit.js" data-promotekit="252f5a29-9802-4d7d-9739-07d5052aae50"></script>
      }
       {/* <title>{meta.title}</title>
       <meta content={meta.description} name="description" key="description" />
       {meta.domain && <link rel="canonical" href={`${meta.domain}${router.pathname}${router.location.search}`} key="canonical" />}

       <meta property="og:title" content={meta.title} key="og-title" />
       <meta property="og:description" content={meta.description} key="og-description" />
       <meta property="og:site_name" content={meta.siteName} key="og-site-name" />
       <meta property="og:image:width" content="1024" key="og:image:width"/>
       <meta property="og:image:height" content="1024" key="og:image:height"/>
       <meta property="og:type" content="website" key="og-type" />
       {meta.domain && <meta property="og:url" content={`${meta.domain}${router.pathname}${router.location.search}`} key="og-url" />}
       {meta.domain && meta.image && <meta property="og:image" content={`${meta.domain}${meta.image}`} key="og-image" />}


       <meta name="twitter:title" content={meta.title} key="twitter-title" />
       <meta name="twitter:description" content={meta.description} key="twitter-description"/>
       <meta name="twitter:card" content="summary_large_image" key="twitter-card" />
       {meta.twitterHandle && <meta name="twitter:site" content={meta.twitterHandle} key="twitter-site" />}
       {meta.domain && meta.image && <meta name="twitter:image" content={`${meta.domain}${meta.image}`} key="twitter-image" />}
       */}
    </Helmet>
  );
}

export default Meta;
