import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useAuth } from '../util/auth';
import analytics from '../util/analytics';

const FilterMenu = ({ properties, values, onApplyFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false); // State to control Dialog visibility
  const filter = createFilterOptions();
  const auth = useAuth();

  useEffect(() => {
    setTags(auth?.user?.tags || []);
  }, [auth]);

  const handleMenuClick = (event) => {
    analytics.track('openFilterMenu');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedProperty('');
    setOpen(false); // Ensure Autocomplete dialog is closed
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handlePropertySelect = (property) => {
    setSelectedProperty(property);
    if (property === 'tags') {
      setDialogOpen(true); // Open dialog for tags
      handleClose();
    } else if (property == 'liked') {
      if (onApplyFilter) {
        onApplyFilter({ property: 'liked', value: true });
      }
      handleClose();
    } else {
      setDialogOpen(false); // Ensure dialog is closed for other properties
    }
  };

  const handleValueSelect = (value) => {
    if (onApplyFilter && selectedProperty) {
      onApplyFilter({ property: selectedProperty, value });
    }
    handleClose();
  };

  const handleTagSelection = (event, value, reason) => {
    if ((reason === 'select-option' || reason === 'create-option') && onApplyFilter) {
      const selectedValue = typeof value === 'string' ? value : value.inputValue;
      onApplyFilter({ property: 'tags', value: selectedValue });
      handleDialogClose(); // Close dialog after selection
    }
  };

  return (
    <div>
      <Button aria-label="filter" onClick={handleMenuClick} startIcon={<FilterListIcon />} size="small" style={{ textTransform: 'none' }}>
        Filter
      </Button>
      <Menu id="filter-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl) && !selectedProperty} onClose={handleClose}>
        {properties.map((property) => (
          <MenuItem key={property} onClick={() => handlePropertySelect(property)} style={{ textTransform: 'capitalize' }}>
            {property}
          </MenuItem>
        ))}
      </Menu>
      <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{paddingBottom: 0}}>Filter Tags</DialogTitle>
        <Autocomplete
          style={{margin: '0px 10px', width: '300px'}}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={handleTagSelection}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== '' && !filtered.length) {
              filtered.push({
                inputValue: params.inputValue,
                title: `Filter "${params.inputValue}"`,
              });
            }
            return filtered;
          }}
          options={tags}
          getOptionLabel={(option) => option.title || option}
          renderInput={(params) => <TextField {...params} label="Tags" variant="outlined" margin="normal" />}
          freeSolo
        />
        <DialogActions>
          <Button onClick={handleDialogClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="value-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(selectedProperty) && Boolean(anchorEl) && selectedProperty !== 'tags'}
        onClose={handleClose}
      >
        {values[selectedProperty]?.map((value) => (
          <MenuItem key={value} onClick={() => handleValueSelect(value)} style={{ textTransform: 'capitalize' }}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FilterMenu;