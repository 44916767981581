import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid, Paper, Typography, InputBase, Chip, Tooltip, IconButton,
  Card, CardHeader, CardContent, CardActions, Avatar, CircularProgress, ButtonGroup,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ThumbUp from '@material-ui/icons/ThumbUpOutlined';
import LoopIcon from '@material-ui/icons/Loop';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import { useCompletion } from "ai/react";
import * as Sentry from '@sentry/react';
import { Masonry } from "react-plock";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import analytics from '../util/analytics';
import { apiRequest } from "../util/util.js";
import { textToHtmlParagraphs } from '../util/util.js';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
    // backgroundColor: th,
  },
  searchBar: {
    // marginLeft: '50px',
    // marginRight: '50px',
    padding: '2px 8px',
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIcon: {
    padding: 3,
  },
  categoriesContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
  },
  cardContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: theme.spacing(2),
    minWidth: 280,
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
  },
  cardIcon: {
    color: '#2583E8',
    fontSize: '18px',
    marginRight: '6px'
  },
  newsCard: {
    backgroundColor: theme.palette.type === 'light'? '#EAF4FF' : '#0e122e',
    paddingTop: theme.spacing(4)
  },
  newsLabel:{
    color: '#2583E8',
    borderColor: '#2583E8',
    borderRadius: '5px',
    fontSize: '11px',
  },
  infoButton: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    
  },
  newsInfoIcon: {
    color: '#2583E8',
    padding: '2px'
  },
  personalCard: {
    backgroundColor: '#E8B724'
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
  
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
  },
}));

const cardStyles = [
  { bgcolor: '#ffffff', color: '#000000', fontSize: '23px', fontFamily: 'Montserrat' },
  { bgcolor: '#ededed', color: '#000000', fontSize: '18px', fontFamily: 'Montserrat' },
  { bgcolor: '#fffaea', color: '#2d2d2d', fontSize: '31px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#ffffff', color: '#2d2d2d', fontSize: '19px', fontFamily: '"Courier New", monospace' },
  { bgcolor: '#eaf4ff', color: '#2d2d2d', fontSize: '35px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#e8eaf6', color: '#2d2d2d', fontSize: '21px', fontFamily: '"Montserrat", sans-serif' },
];

const nl2br = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  // Split by '. ', '! ', and '? ', but keep the delimiters
  // Exclude splits where the period is preceded by a number (e.g., "1. ", "2. ")
  return str.split('\n').map((line, index) => (
      `{line}
      {index !== str.split('\n').length - 1 && <br />}`
  ));
};

const ContentCard = ({ content, onClick }) => {
  const classes = useStyles()

  return (
    <Card elevation={0} className={classes.card} >

      <CardContent className={classes.content} onClick={onClick}>
        <Typography variant="body" color="textPrimary" component="p" dangerouslySetInnerHTML={{__html: content}}>
        </Typography>
      </CardContent>
    </Card>
  );
};




const TemplatesDashboard = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSearchTerm, setActiveSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [postType, setPostType] = useState('twitter');

  const queryClient = useQueryClient();

  const { data: generatedIdeas = [], isFetching, error } = useQuery({
    queryKey: ['templates', activeSearchTerm],
    queryFn: () => fetchIdeas(activeSearchTerm, auth?.user?.auth.currentUser.getIdToken()),
    enabled:  !!auth?.user,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 24000 * 60 * 60, // Cache for 24 hour
    initialData: () => {
      const cachedData = queryClient.getQueryData(['templates', activeSearchTerm]);
      return cachedData || undefined;
    },
  });

  const generateIdeasMutation = useMutation({
    mutationFn: (keyword) => fetchIdeas(keyword, auth?.user?.auth.currentUser.getIdToken()),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['templates', variables], data);
      setActiveSearchTerm(variables);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_template`, variables);
      }
    },
  });

  const fetchIdeas = async (keyword, token) => {
    console.log("IT'S BEING CALLED ", keyword)
    const IdToken = await token
    const response = await fetch('/api/inspiration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${IdToken}`,
      },
      body: JSON.stringify({ 'type': 'template', 'keyword': keyword }),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch templates');
    }
  
    const data = await response.json();
    return data;
  }

  const handleCategoryToggle = (category) => {
    analytics.track('clickTemplateCategory', {category: category})
    setSearchTerm(category);
    const cachedData = queryClient.getQueryData(['templates', category]);
    if (!cachedData) {
      generateIdeasMutation.mutate(category);
    } else {
      setActiveSearchTerm(category);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_template`, category);
      }
    }
  };

  const handleIdeaClick = (idea) => {
    analytics.track('clickTemplate', {idea: idea})
    router.push(`/notes/new`, { note: { content: idea, id: null } });
  };

  const handleSearchSubmit = (event) => {
    if (event) event.preventDefault();
    analytics.track('searchIdea', {searchTerm})
    if (generateIdeasMutation.isLoading) return;
    const cachedData = queryClient.getQueryData(['templates', searchTerm]);
    if (!cachedData) {
      generateIdeasMutation.mutate(searchTerm);
    } else {
      setActiveSearchTerm(searchTerm);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_template`, searchTerm);
      }
    }
  };

  useEffect(() => {
    let _categories = ['all', 'learnings', 'celebrate', 'tips', 'myths', 'tools curation', 'expertise', 'mistakes', 'experience', 'stories']
    setCategories(_categories)
  }, [auth.user]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={classes.root}>

            
      {/* <Paper elevation={0} component="form" className={classes.searchBar} onSubmit={handleSearchSubmit}>
        {(generateIdeasMutation.isLoading || isFetching) ? <CircularProgress className={classes.searchIcon}/> : <SearchIcon className={classes.searchIcon} />}
        <InputBase
          className={classes.input}
          placeholder="content creation"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button type="submit" disabled={generateIdeasMutation.isLoading}>
          Search
        </Button>
      </Paper> */}
      
      {/* <Button onClick={handleRefresh} disabled={generateIdeasMutation.isLoading || !activeSearchTerm}>
        Refresh Ideas
      </Button> */}
      
      <div className={classes.categoriesContainer}>
        <div>
        {auth?.user && categories.map((category) => (
          <Chip
            key={category}
            label={category}
            disabled={generateIdeasMutation.isLoading}
            onClick={() => handleCategoryToggle(category)}
            color={category === activeSearchTerm ? "primary" : "default"}
            className={classes.chip}
          />
        ))}
        </div>
      </div>
      
      {generatedIdeas.length < 1 && 
        <div className={classes.cardContainer}>
           {new Array(9).fill(0).map((_, index) => (
          <Skeleton variant="rect" width={'25%'} height={200} className={classes.card} style={{backgroundColor: '#ddd'}}/>
           ))}
        </div>
      }
      <Grid container spacing={4} className={classes.cardContainer}>
        {auth?.user && (
          <Masonry
            config={{
              columns: [1, 2, 3],
              gap: [1, 12, 6],
              media: [640, 768, 1024],
            }}
            items={generatedIdeas ?? []}
            render={(item, index) => (
              <ContentCard
                content={textToHtmlParagraphs(item)}
                onClick={() => handleIdeaClick(textToHtmlParagraphs(item))}
              />
            )}
          />
        )}
      </Grid>
    </div>
  );
};

export default TemplatesDashboard;