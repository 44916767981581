import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  typingIndicator: {
    display: 'inline-block',
    height: '20px',
    width: '60px',
    borderRadius: '10px',
    backgroundColor: '#e0e0e0',
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: '3px',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: '#9e9e9e',
    },
    '&::before': {
      left: '10px',
      animation: '$typingDot 1.5s infinite ease-in-out',
    },
    '&::after': {
      left: '30px',
      animation: '$typingDot 1.5s infinite ease-in-out 0.3s',
    },
  },
  '@keyframes typingDot': {
    '0%, 100%': {
      transform: 'scale(0)',
    },
    '50%': {
      transform: 'scale(1)',
    },
  },
}));

const TypingIndicator = () => {
  const classes = useStyles();
  return <div className={classes.typingIndicator} />;
};

export default TypingIndicator;