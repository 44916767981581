import React, { useEffect, useState } from 'react';
import { Dialog, Button, DialogTitle, DialogContent, TextField, IconButton, Chip, Checkbox, DialogActions } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import analytics from '../util/analytics';
import { useAuth } from '../util/auth';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initialTags = [];
const filter = createFilterOptions();

export default function TagManagementDialog({ isOpen, handleClose, selectedTags, updateTags }) {
  const [globalTags, setGlobalTags] = useState(initialTags);
  const [tags, setTags] = useState(selectedTags);
  const auth = useAuth();

  useEffect(() => {
    setGlobalTags(auth?.user?.tags || initialTags);
  }, [auth]);

  useEffect(() => {
    setTags(selectedTags);
  }, [selectedTags]);

  const handleDeleteGlobalTag = (tagToDelete) => {
    const updatedGlobalTags = globalTags.filter(tag => tag !== tagToDelete);
    setGlobalTags(updatedGlobalTags);
    console.log("Delete global tag", tagToDelete)
    // Optionally, update the backend to reflect the global tag deletion
  };

  const handleUpdate = () => {
    const newTags = tags.map(tag => tag.value || tag);
    analytics.track('updateTags', {tags: newTags})
    updateTags(newTags);
    handleClose();
  }

  const removeTagFromPost = (tagToRemove) => {
    const updatedTags = tags.filter(tag => tag !== tagToRemove);
    setTags(updatedTags);
  };

  const handleTagChange = (event, newValue, reason) => {
    // Directly set the new tags, adding non-existing ones to globalTags if needed
    setTags(newValue);
    // if (reason === 'select-option' || reason === 'create-option') {
    //   newValue.forEach(newTag => {
    //     if (!globalTags.includes(newTag)) {
    //       setGlobalTags(prevGlobalTags => [...prevGlobalTags, newTag]);
    //       // Optionally, update the backend to include the new global tag
    //     }
    //   });
    // }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Update Tag</DialogTitle>
      <DialogContent style={{ width: '600px', maxWidth: '80vw', padding: '10px' }}>
        <Autocomplete
          multiple
          freeSolo
          disableCloseOnSelect
          id="tags-autocomplete"
          options={globalTags}
          getOptionLabel={(option) => option.label || option}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.includes(inputValue);
            if (inputValue !== '' && !isExisting) {
              filtered.push({label: `Create '${inputValue}' tag`, value: inputValue});
            }
            return filtered;
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label || option}
              {/* {!option.label &&
              <IconButton style={{ marginLeft: 'auto' }} size="small" onClick={() => handleDeleteGlobalTag(option.value || option)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              } */}
            </React.Fragment> 
          )}
          value={tags}
          onChange={handleTagChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.value || option}
                {...getTagProps({ index })}
                onDelete={() => removeTagFromPost(option.value || option)}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Tags" placeholder="Add or remove tags" fullWidth />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleUpdate} >
          Save
        </Button>
      </DialogActions>
      
    </Dialog>
  );
}
