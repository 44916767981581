import React, { useEffect } from 'react';
import {
  Typography,
  Divider,
} from '@material-ui/core';

import { useAuth } from '../util/auth';
import Post from './Post';

function useInfiniteScroll(fetchNextPage, hasNextPage, data) {
  const auth = useAuth();
  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100 &&
        hasNextPage
      ) {
        fetchNextPage();
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasNextPage, auth, data]);
}

const PostList = (props) => {
  const {data, fetchNextPage, hasNextPage, isLoading, isError, currentNote} = props

  //let { data, fetchNextPage, hasNextPage, isLoading, isError } = usePagedPostsByOwner(auth?.user?.id)

  useInfiniteScroll(fetchNextPage, hasNextPage, data)

  return (
    <>{ !isLoading && data && data.pages[0].items.length === 0 &&
        <Typography variant="h6" component="p" align="center" style={{ fontSize: '16px', padding: '20px', marginTop: '200px'}}>
          It's empty now... <br></br>Share your thoughts and watch them transform into posts here!
        </Typography>
      }
      {isLoading && 
        <Typography>Loading...</Typography>
      }
      {!isLoading && data && data.pages && data.pages.map((page) =>
        page.items.map((post) => (
        <>
        <Post note={post}/>
        <Divider/>
        </>
      )))}
    </>
  );
};

export default PostList;