import React, { useState} from "react";
import Meta from "./../components/Meta";
import IdeaDashboard from "./../components/IdeaDashboard";
import InpsirationDashboard from "../components/InspirationDashboard";
import TemplatesDashboard from "../components/TemplatesDashboard";
import { useRouter } from "./../util/router";
import { requireAuth } from "./../util/auth";
import NoteNav from "../components/NoteNav";
import { Box, useMediaQuery, Button, ButtonGroup } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import analytics from '../util/analytics.js';

const dashboards = {
  ideas: <IdeaDashboard type="titles"/>,
  questions: <IdeaDashboard type="questions"/>,
  inspirations: <InpsirationDashboard/>,
  templates: <TemplatesDashboard/>
}

function IdeasPage(props) {
    document.title = "Cleve - Ideas";

  const router = useRouter();
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'));
  const mode = router.query.mode || 'ideas';

  return (
    <>
      <Meta title="Ideas" />
      <Box style={{paddingLeft: isNotDesktop? 0 : '240px'}}>
        <Box style={{paddingLeft: '40px', marginTop: '20px', marginBottom: '-20px'}}>
          <ButtonGroup style={{margin: 'auto'}}>
            {
              Object.keys(dashboards).map((key) => (
                <Button
                  size={isNotDesktop? 'small': 'medium'}
                  onClick={() => {router.replace('/explore?mode=' + key); analytics.track(`explore_${key}`)}} 
                  color={mode === key? 'primary': 'default'}
                  style={{opacity: mode === key? 1: 0.8}}
                  variant={mode === key? 'contained': 'outlined'}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </Button>
              ))
            }
          </ButtonGroup>
        </Box>
        {dashboards[mode]}
      </Box>
      <Box py={3}/>
      <NoteNav />
    </>
  );
}

export default requireAuth(IdeasPage);
