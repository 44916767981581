"use client";
import ChatSection from "../components/ChatSection";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Button, ButtonGroup, Paper, Tab, Tabs, BottomNavigation, BottomNavigationAction, useMediaQuery, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from '@material-ui/core/Snackbar';
import { useChat } from "ai/react";
import React, { useState, useEffect, useRef }from "react";
import Meta from "../components/Meta";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth";
import { Link, useRouter } from "./../util/router";
import ChatIcon from '@material-ui/icons/ForumOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CreateIcon from '@material-ui/icons/Create';
import Chat from "../components/Chat";
import { usePagedPostsByOwner, usePagedPostByNoteId, useNoteOnce } from "../util/db";
import Tiptap from "../components/TipTap";
import Section from "../components/Section";
import NoteList from "../components/NoteList";
import PostList from "../components/PostList";
import NoteNav from "../components/NoteNav";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    position: 'sticky',
    width: '100%',
    top: 0,
    boxShadow: 'none',
    color: theme.palette.text.primary,
    zIndex: 1000, // Ensure it stays on top of other content
    backgroundColor: theme.palette.background.default, // Optional: Set background color to match your theme
  },
  container: {
    maxWidth: '800px',
    margin: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  desktopContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'space-between',
    flexDirection: 'row',
    
  },
  postContainer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: '0px',
    padding: '20px',
    borderLeft: '1px solid #e0e0e0',
    overflowY: 'scroll',
    maxWidth: '45vw',
    overflowY: 'auto',
    minHeight: '90vh',
    heighth: '90vh',
    maxHeight: '90vh'
  },
  noteContainer: {
    maxWidth: '45vw',
    width: '100%',
    margin: 'auto',
    marginTop: 0,
    padding: '1vw',
    overflowY: 'auto',
    heighth: '90vh',
    maxHeight: '90vh',
  },
  root: {
    position: 'fixed',
    width: '600px',
    maxWidth: '100%',
    left: 0,
    bottom: 0,
  },
  tabPanel: {
    paddingBottom: '60px'
  }
  // tabsContainer: {
  //   [theme.breakpoints.up('md')]: {
  //     maxWidth: '800px', // Adjust based on your preference
  //     margin: 'auto',
  //   }
  // },
}));


function TabPanel(props) {
  const classes = useStyles();
  
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel}> {/* Padding around the content */}
          {children}
        </Box>
      )}
    </div>
  );
}

function NotePage(props) {
  document.title = "Cleve - Notes";
  const classes = useStyles();
  const auth = useAuth()
  const router = useRouter();
  const theme = useTheme();
  const isNotDesktop = useMediaQuery(theme.breakpoints.down('md'));
  //const {data: posts, isLoading} = usePosts()
  const { data: noteData, status: noteStatus } = useNoteOnce(router.query.id);


  const [value, setValue] = useState(router.query.tab === 'posts' ? 1 : 0);
  const [open, setOpen] = useState(true);
  const [currentNote, setCurrentNote] = useState(router.location?.state?.note);

  let loadingNoteToast = useRef(null)

  useEffect(() => {
    if(router.query.id && !currentNote) {
      if (noteStatus == 'loading') {
        loadingNoteToast.current = toast.loading('Loading note...')
      } else if (noteStatus === 'success' && noteData) {
        setCurrentNote(noteData)
        toast.dismiss(loadingNoteToast.current)
      } else if (noteStatus === 'error') {
        toast.update(loadingNoteToast.current, { render: `Note not found`, type: "error", isLoading: false, autoClose: 2000 });
        router.history.goBack()
      }
      
    }
  }, [noteData, noteStatus])

  useEffect(() => {
    if (auth.user && !auth.user.questions){
      router.push('/onboarding')
    }
  }, [auth]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const pagedPost = usePagedPostByNoteId(auth?.user?.id, currentNote?.id)

  return (
    <Box> {/* paddingTop should be equal or greater than the height of your tabs to prevent content from being hidden behind the sticky tabs */}
      <Meta title="Cleve" />
      {router.query.paid &&
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal:'center'}}>
        <Alert onClose={handleClose} severity="success">
          { auth.user?.planIsActive && !router.query.credit ? `Congrats! You are now subscribed to Cleve 🥳`: ''}
          { router.query.credit ? `You successfully bought ${router.query.credit} credit!` : ''}
        </Alert>
      </Snackbar>
      }
      {
        isNotDesktop ? (
          <Box className={classes.container}>
            { currentNote?.id &&
              <Paper square className={classes.tabsContainer}>
                
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="icon label tabs example"
                  className={classes.tabsContainer}
                  style={{display: currentNote? 'block':'none'}}
                  variant="fullWidth"
                >
                  <Tab label="Note" aria-label="notes" />
                  <Tab label="Posts" onClick={() => setValue(1)} aria-label="posts" />
                </Tabs>
              </Paper>
            }
            <TabPanel value={value} index={0}>
                <Tiptap currentNote={currentNote} setCurrentNote={setCurrentNote} setTabId={setValue} fullscreen/>
            </TabPanel>
            
            <TabPanel value={value} index={1}>
              <PostList {...pagedPost} currentNote={currentNote} />
            </TabPanel>
          </Box>
        ) :
        (
          <Box className={classes.desktopContainer} style={{marginLeft: currentNote? '15vw' : '0px' }}>
            
            <Box className={classes.noteContainer}>
              
              <Tiptap currentNote={currentNote} setCurrentNote={setCurrentNote} setTabId={setValue}/>
            </Box>
            { currentNote?.id && 
            <Box className={classes.postContainer}>
              
              <PostList {...pagedPost} currentNote={currentNote}/>
            </Box>
            }
          </Box>
        )
      }
      
      <NoteNav/>
    </Box>
  );
}

export default requireAuth(NotePage);