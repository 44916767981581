import React, { useState, useEffect } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  makeStyles,
  Tooltip,
  Button,
  useTheme
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { deleteNote, updateNote, updateUser } from '../util/db';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import { useSnackbar } from './SnackbarContext';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import TagManagementDialog from './TagManagementDialog';
import analytics from '../util/analytics';
import NoteSearch from './NoteSearch';
import NoteCard from './NoteCard';

// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
  },
  actionArea: {
    // You can define styles for the clickable area if necessary
  },
  content: {
    position: 'relative',
  },
  noteContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: '35px',
    maxHeight: '160px', /* Adjust based on your requirement */
    transition: 'max-height 0.3s ease',

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: 50,
      left: 0,
      right: 0,
      height: '70%',
      background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`, /* Adjust the color to match your background */
      PointerEvent: 'none', /* Prevents the pseudo-element from being clickable */
    },
  },
  
  expanded: {
    maxHeight: 'none',

    '&::before': {
      display: 'none' /* Hide the fading effect when expanded */
    },
  },

  seeMoreButton: {
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    opacity: 0.7,
    '&:hover':{backgroundColor: 'transparent'}
  },

  date: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: 'gray',
    fontSize: '2 rem'
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    marginRight: theme.spacing(1),
    zIndex: 1
  },
  optionsButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 2, // ensure the button is above other elements
  },
  editButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    zIndex: 1
  },
}));

function useInfiniteScroll(fetchNextPage, hasNextPage, data) {
  const auth = useAuth();
  useEffect(() => {
    function handleScroll() {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100 &&
        hasNextPage
      ) {
        fetchNextPage();
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchNextPage, hasNextPage, auth, data]);
}

const NoteList = (props) => {
  const { data, fetchNextPage, hasNextPage, isLoading, currentNote, setCurrentNote } = props;

  useInfiniteScroll(fetchNextPage, hasNextPage, data);

  return (
    <>
      <NoteSearch />
      {!isLoading && data && data.pages && data.pages.map((page) =>
        page.items.map((note) => (
          <React.Fragment key={note.id}>
            <NoteCard
              note={note}
              setCurrentNote={setCurrentNote}
            />
            <Divider />
          </React.Fragment>
        ))
      )}
    </>
  );
};

export default NoteList;