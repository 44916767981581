import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Avatar from '@material-ui/core/Avatar';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link, Router, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useDarkMode } from "./../util/theme";
import StarsIcon from '@material-ui/icons/Stars';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography, useMediaQuery, useTheme, Dialog } from "@material-ui/core";
import { checkResetSaveNoteStreak, updateUser} from '../util/db';
import {StreakIcon} from '../util/icons';
import { toast } from "react-toastify";
import Intercom from '@intercom/messenger-js-sdk';

const useStyles = makeStyles((theme) => ({
  logoImage: {
    height: 32,
    margin: theme.spacing(0.5),
  },
  logo: {
    margin: theme.spacing(1),
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: 'row'
  },
  creditIcon: {
    fontSize: 15,
    padding: 0,
  }
}));


function Navbar(props) {
  const classes = useStyles();
  const router = useRouter();

  const auth = useAuth();
  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  //const drawerWidth = isMobile ? 0 : Math.min(240, 15);

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;


  const checkAndResetStreak = async () => {
    if(auth.user){
      if (auth.user?.stats?.lastNoteDate?.toDate && auth.user?.stats?.notesStreakCount > 0){
        const today = new Date();
        const lastNoteDate = auth.user.stats.lastNoteDate.toDate();
        //if lastNoteDate and today diff is more than 1 day, reset sterak
        if((today.getDate() - lastNoteDate.getDate()) > 1){
          await updateUser(auth.user.uid, {"stats.notesStreakCount": 0});
        }
      }
    }
  }

  useEffect(() => {
    if (auth?.user) {
      const user = auth.user;
      Intercom({
        app_id: 'rf361um7',
        user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: user.displayName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
      });
    } else {
      Intercom({
        app_id: 'rf361um7',
      });
    }

    if(auth?.user?.stats) {
      checkAndResetStreak();
    }
  }, [auth]);

  const handleBack = () => {
    router.history.goBack();
    //router.history.push("/");
  };
  

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  
  const handleCloseMenu = () => {
    setMenuState(null);
  };

  

  return ['/onboarding', '/linkedin-callback', '/roastme'].includes(router.pathname) ? (<></>) : (
    <Section bgColor={props.color} size="auto">
      <AppBar position="static" color="transparent" elevation={0}
        style={{
          ...(!isMobile && {
            // Calculate width as `calc(100% - min(15vw, 240px))`
            width: `calc(100% - min(15vw, 240px))`,
            marginLeft: 'min(15vw, 240px)',
          }),
        }}
      >
        <Container disableGutters={true}>
          <Toolbar style={{minHeight: '32px'}}>
            {router.showBackButton &&
            <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBack}>
              <ArrowBackIcon />
            </IconButton>
            }
            {/* {auth.user && auth.user.credit !== "undefined" && isMobile && 
              <>
                <Tooltip title={`${auth.user?.stats?.notesStreakCount ?? 0} day streak of saving your thoughts!`}>
                  <Button 
                    onClick={() => {

                      toast(`${auth.user?.stats?.notesStreakCount ?? 0} day streak of saving your thoughts!`, {
                        icon: <StreakIcon color="primary" />,
                      })
                    }}
                    edge="start"
                    className={classes.creditIcon} 
                    startIcon={<StreakIcon color={new Date().getDate() === auth?.user?.stats?.lastNoteDate?.toDate()?.getDate()? 'primary' : 'inherit'} />}> 
                  {auth.user?.stats?.notesStreakCount ?? 0} 
                </Button>
                </Tooltip>
                <Tooltip title={`You have ${auth.user.extraCredit? auth.user.credit + auth.user.extraCredit : auth.user.credit} posts generation left`}>
                 <Button component={Link} to="/pricing" edge="start" className={classes.creditIcon} startIcon={<StarsIcon />}> {auth.user.extraCredit? auth.user.credit + auth.user.extraCredit : auth.user.credit} </Button>

                </Tooltip>
              </>
              } */}
            <div className={classes.spacer}>
              <Link to="/" underline="none" className={classes.logo}>
                <img src={logo} alt="Logo" className={classes.logoImage} />
                <Typography variant="h5" component="h1" color="inherit">Cleve</Typography>
              </Link>
            </div>
            <Hidden mdUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown={true} implementation="css">
              {auth.user && auth.user.credit !== "undefined" &&
              <>
                <Tooltip title={`${auth.user?.stats?.notesStreakCount ?? 0} day streak of saving your thoughts!`}>
                  <Button 
                    onClick={() => {

                      toast(`${auth.user?.stats?.notesStreakCount ?? 0} day streak of saving your thoughts!`, {
                        icon: <StreakIcon color={new Date().getDate() === auth?.user?.stats?.lastNoteDate?.toDate?.()?.getDate?.()? 'primary' : 'inherit'}/>,
                      })
                    }}
                    edge="start"
                    className={classes.creditIcon} 
                    startIcon={<StreakIcon color={new Date().getDate() === auth?.user?.stats?.lastNoteDate?.toDate?.()?.getDate?.()? 'primary' : 'inherit'} />}> 
                  {auth.user?.stats?.notesStreakCount ?? 0} 
                </Button>
                </Tooltip>
                <Tooltip title={`You have ${auth.user.extraCredit? auth.user.credit + auth.user.extraCredit : auth.user.credit} posts generation left`}>
                 <Button component={Link} to="/pricing" edge="start" className={classes.creditIcon} startIcon={<StarsIcon />}> {auth.user.extraCredit? auth.user.credit + auth.user.extraCredit : auth.user.credit} </Button>

                {/* <Button edge="start" className={classes.creditIcon} endIcon={<StarsIcon />}> {auth.user.credit} </Button> */}
                </Tooltip>
              </>
              }
              
              <Button component={Link} to="/pricing" color="inherit">
                Pricing
              </Button>
              {/* <Button component={Link} to="/about" color="inherit">
                About
              </Button>
              <Button component={Link} to="/contact" color="inherit">
                Contact
              </Button> */}
              {/* <Button component={Link} to="/" color="inherit">
                Chat
              </Button> */}
              {/* <Button component={Link} to="/video" color="inherit">
                Video
              </Button> */}
              {/* <Button component={Link} to="/gallery" color="inherit">
                Gallery
              </Button>   */}

              {/* <Button component={Link} to="/faq" color="inherit">
                FAQ
              </Button> */}


              {!auth.user && (
                <>
                  <Button component={Link} to="/auth/signin" color="inherit">
                    Sign in
                  </Button>
                  <Box component="span" ml={1}>
                    <Button
                      component={Link}
                      to="/auth/signup"
                      variant="contained"
                      color="primary"
                    >
                      Sign up
                    </Button>
                  </Box>
                </>
              )}

              {/* {auth.user && (
                <>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    Account
                    <ExpandMoreIcon className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem component={Link} to="/settings/general">
                      Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      Signout
                    </MenuItem>
                  </Menu>
                </>
              )}               */}
              <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          {/* <ListItem component={Link} to="/chat" button={true}>
            <ListItemText>Chat</ListItemText>
          </ListItem> */}
          
          <ListItem component={Link} to="/pricing" button={true}>
            <ListItemText>Pricing</ListItemText>
          </ListItem>
          {/* <ListItem component={Link} to="/faq" button={true}>
            <ListItemText>FAQ</ListItemText>
          </ListItem> */}
          {/* <ListItem component={Link} to="/about" button={true}>
            <ListItemText>About</ListItemText>
          </ListItem> */}

          {!auth.user && (
            <>
              <ListItem component={Link} to="/auth/signin" button={true}>
                <ListItemText>Sign in</ListItemText>
              </ListItem>
              <ListItem>
                <Button
                  component={Link}
                  to="/auth/signup"
                  variant="contained"
                  color="primary"
                >
                  Sign up
                </Button>
              </ListItem>
            </>
          )}

          {auth.user && (
            <>
              <ListItem component={Link} to="/settings/general" button={true}>
                <ListItemText>Settings</ListItemText>
              </ListItem>
              <ListItem 
                component={'a'} 
                to='https://cleve.ai/affiliates'
                href='https://cleve.ai/affiliates'
                target='_blank'
                rel='noopener noreferrer'
                button={true}>
                <ListItemText>Refer & Earn</ListItemText>
              </ListItem>
              {auth.user && auth.user.credit !== "undefined" &&
              <ListItem>
                <Tooltip title={`You have ${auth.user.extraCredit? auth.user.credit + auth.user.extraCredit : auth.user.credit} credit left`}>
                  <Button component={Link} to="/credit" edge="start" className={classes.creditIcon} startIcon={<StarsIcon />}> {auth.user.extraCredit? auth.user.credit + auth.user.extraCredit : auth.user.credit} </Button>
                </Tooltip>
              </ListItem>
              }
              <Divider />
              <ListItem
                button={true}
                onClick={(event) => {
                  auth.signout();
                }}
              >
                <ListItemText>Sign out</ListItemText>
              </ListItem>
            </>
          )}

          <ListItem>
            <IconButton
              color="inherit"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>
          </ListItem>
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
