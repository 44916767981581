import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What is Cleve?",
      answer: "Cleve is an AI-powered content workspace for creators."
    },
    {
      question: "How are you different from ChatGPT?",
      answer: "While ChatGPT is a great product which we use too for other purposes, it's not really optimized for creating content. There's a lot of nuances that AI needs to capture when it comes to creating personalized content, like tone, writing style, platform optimization and more.\n\nThere's some examples higher up in this page where we put Cleve and ChatGPT to the test.\n\nWith Cleve you also have a full workflow."
    },
    {
      question: "How do Cleve's credits work?",
      answer: "With the free tier, you have 20 credits. Each time you Generate a post, that needs 1 credit.\n\nMany users also use Cleve primarily as a note-taking tool to document your knowledge and experiences, and we wanted to keep that free.\n\nAll your saved notes, even with the AI 'Ask me' questions do not use your credits. So feel free to use that as much as you'd like."
    },
    {
      question: "Will Cleve be adding more features after I sign up for Cleve Pro?",
      answer: "Yes, you will have access to more pro features as we develop them"
    },
    {
      question: "I'm interested in exploring Cleve for my PR/Branding/Marketing agency",
      answer: "Perfect. Let's have a chat. Click 'Talk to Sales' under the Enterprise plan. We have a number of features that makes managing your client's content much easier and can customize it to your needs."
    },
    {
      question: "I'm interested in using Cleve for my organisation",
      answer: "Awesome. Thought leadership content can be a great avenue to drive growth across your company, especially for B2B services sectors with LinkedIn. Click 'Talk to Sales' under the Enterprise plan.\n\nCompanies benefit from the collaborative features, where you're able to add your in-house communications specialists, compliance managers or copywriters and more."
    }
  ];


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6"><strong>{item.question}</strong></Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography variant="body2">
                {item.answer.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
