import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import {Box, Card, CardContent, CardHeader, Grid, Paper, Avatar} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: theme.palette.background.default,
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
  firstCard: {
    margin: theme.spacing(1),
    width: '260px',
    maxWidth: '300px',
    fontSize: '0.8rem',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'rgba(155, 155, 155, 0.1) 0px 2px 6px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px'
  },
  comparisonCard: {
    margin: theme.spacing(1),
    width: '260px',
    maxWidth: '300px',
    fontSize: '0.8rem',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'rgba(155, 155, 155, 0.1) 0px 2px 6px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px'
  },
  comparisonCardContent:{

  },
  featureItem: {
    background: theme.palette.background.default,
    border: 'none',
    boxShadow: 'none'
  }
}));

function ComparisonSection(props) {
  const classes = useStyles();

  const items = [
    {
      "question": "Round 1: A reflective LinkedIn post",
      "answer": "Cleve's output generally sounds more human, utilizes simple sentences, is more personal and easier to follow. \n\n Generally these kinds of posts perform better on LinkedIn too as they're more skimmable.",
      "cards": [
        {
          "title": "User notes",
          "content": "<strong>Can you talk about a time when a piece of feedback significantly altered your business strategy or approach?</strong><br/><br/>There was a mentor who told me to really focus a lot on understanding myself - not the most businessy advice, but it changed things a lot.<br/><br/><strong>What gives me energy, what takes it away. Where my skills were and where my gaps were.</strong><br/><br/><strong>How did this self-reflection and understanding alter the way you approach your business decisions and interactions with your team or clients?</strong><br/><br/>Generally being more conscious about it helped me focus on things that I'm best in and know when to delegate better in the business. I felt happier in the business and more confident and we made far more progress when everyone was doing things ideal for them."
        },
        {
          "title": "ChatGPT",
          "content": "🌟 Embracing Self-Understanding: A Game-Changer in Business Strategy 🌟<br/><br/>Reflecting on pivotal moments in my entrepreneurial journey, I'm reminded of the transformative power of feedback. It's not always the conventional wisdom that shapes our path, but rather the unexpected insights that leave an indelible mark.<br/><br/>One such moment was when a mentor imparted what initially seemed like non-traditional advice: 'Focus on understanding yourself.' While it may not have sounded like typical business counsel, it proved to be a catalyst for profound change.<br/><br/>Delving deep into self-awareness became my compass, guiding me through the complexities of business decisions. I embarked on a journey to discern what ignited my passion and what drained my energy. I meticulously mapped my skills, identifying both my strengths and areas for growth.<br/><br/>Here's to the power of introspection in reshaping our business strategies and unlocking our true potential! #BusinessTransformation #SelfAwareness #LeadershipJourney #TeamEmpowerment #SuccessMindset"
        },
        {
          "title": "Cleve",
          "content": "A mentor once offered me life-changing guidance: Gain a deep understanding of yourself.<br/><br/>This advice might not sound like a typical business strategy, but it drastically shifted my perspective.<br/><br/>I started to explore what activities gave me energy and which ones drained me. I identified my strengths and weaknesses.<br/><br/>Recognizing these aspects allowed me to lean into tasks I excelled at and delegate others more effectively.<br/><br/>This self-awareness led to happier workdays, more confidence, and noticeable progress within my team.<br/><br/>Focusing on tasks that play to our strengths and understanding when to delegate has not only improved our business's performance but also our workplace satisfaction.<br/><br/>You know, real magic happens when people find tasks that fit them perfectly. Actually, it's a smart move for business too."
        }
      ]
    },
    {
      "question": "Round 2: LinkedIn post about an event",
      "answer": "Cleve's writeup was far more personal, feels more conversational and more natural. The writing is a lot more clear and mature. GPT4 did perform far better than GPT3.5 though, but the over-enthusiastic energy was a bit off-putting.",
      "cards": [
        {
          "title": "User notes",
          "content": "recently joined the aws startup day<br/><br/>• pretty amazing stories from local founders<br/><br/>• learned a lot about what amazon's doing in the local space"
        },
        {
          "title": "GPT4",
          "content": "🌟 Just wrapped up an inspiring day at AWS Startup Day! 🌟<br/><br/>Diving deep into the local startup scene, I was blown away by the incredible stories shared by fellow founders. From game-changing innovations to overcoming challenges, the energy was electric!<br/><br/>One major highlight? Getting the inside scoop on Amazon's initiatives in our very own backyard. Talk about eye-opening! 😲<br/><br/>Feeling pumped and ready to bring some of that magic back to my own startup journey. Here's to learning, growing, and making waves together! 🚀 #AWSStartupDay #StartupLife #InnovationNation"
        },
        {
          "title": "Cleve",
          "content": "Recently, I had the chance to join the AWS Startup Day, and let me tell you, it was nothing short of inspiring.<br/><br/>I got to hear amazing stories from local founders. Their journeys, the challenges they faced, and how they overcame them opened my eyes to new possibilities.<br/><br/>One key takeaway for me was learning about Amazon's involvement in the local space. It's impressive how they're supporting startups and fostering innovation right here in our community.<br/><br/>This experience made me realize the importance of not just dreaming big but also taking concrete steps towards those dreams.<br/><br/>Hearing firsthand from those who've been in the trenches and emerged successfully gave me a wealth of knowledge and motivation.<br/><br/>My biggest lesson? The power of community and support in the startup world cannot be underestimated.<br/><br/>In essence, the AWS Startup Day wasn't just an event; it was a pivotal point in my entrepreneurial journey. It reinforced my belief that with the right mindset, support, and resources, making a significant impact is within reach."
        }
      ]
    },
    {
      "question": "Round 3: Instagram post about a personal experience",
      "answer": "Instagram posts (value-based) tend to be more conversational too, especially when coming from a personal brand like a coach or trainer. People need to build a strong connection with you and feel like they're really talking to you for the content to perform. ChatGPT's piece somehow feels very ChatGPT. Extremely inauthentic and odd. Whereas Cleve's was a lot more in depth, but also it feels like it's really written by a human.",
      "cards": [
        {
          "title": "User notes",
          "content": "Can you share an insight or trend you've observed in the Asian market that you think is underrepresented in global business discourse?<br/><br/>• people are so scared to speak up in asia, so generally everything is underrepresented<br/><br/>• although we have a lot of extremely capable people in asia, we're generally brought up to shy away from the limelight and listen to authority<br/><br/>• something i struggle with too"
        },
        {
          "title": "GPT4",
          "content": "Hey everyone! 🌍<br/><br/>Something's been on my mind lately about the Asian market, and I think it's worth talking about more globally. 🗣️<br/><br/>In many parts of Asia, there's a cultural norm where speaking up can be really daunting. We're often taught to keep a low profile and respect authority, which might lead us to hold back our ideas and innovations. 🌀<br/><br/>Despite this, Asia is brimming with incredibly talented and capable individuals who could lead global conversations if given the spotlight. 💡<br/><br/>Let's start recognizing and empowering these voices! Who's with me? 🙌<br/><br/>#SpeakUp #AsianInnovation #GlobalBusiness #Empowerment"
        },
        {
          "title": "Cleve",
          "content": "I've noticed something about the way we do business in Asia that most folks outside might not see.<br/><br/>Even though we have tons of talented people here, we're often holding ourselves back. It's like we're taught to avoid standing out and to always respect authority without question. Honestly, it's something I'm wrestling with, too.<br/><br/>In my circle, it's rare to hear someone challenge the status quo or pitch a groundbreaking idea, not because they can't, but because they're apprehensive about stepping into the spotlight. This mindset might be keeping us from making some real noise on the global stage and putting out ideas that could change the game.<br/><br/>The funny thing is, despite this cultural norm, I know so many capable folks here simmering with ideas that could rival any Silicon Valley startup. We just need that nudge to speak louder. It's a peculiar contrast, underrepresentation not from lack of capability but from a cultural inclination to keep a low profile.<br/><br/>For instance, launching a business straight out of university is seen as foolhardy by many here, but look at the tech giants of the West; many of them were college dropouts. It's this kind of risk-taking and breaking from tradition that I sometimes wish was more embedded in our ethos.<br/><br/>So, while I'm here, trying to make my way and maybe, just maybe, challenge how things are traditionally done, thought you'd appreciate this little insight into what it's like on this side of the pond. It's a journey, alright, trying to mesh what I've grown up believing with where I think we should head. Keep you posted on how that goes!"
        }
      ]
    }
    
  ];

  const features = [
    {
      title: "Cleve gets you",
      description: "Cleve maps out your brain as you offload your thoughts. Get smarter content over time.",
      icon: "https://framerusercontent.com/images/uiNM40zblu1jAFowCT2nkxusoDc.png"
    },
    {
      title: "There's no prompting",
      description: "Just brain dump your thoughts like you do on your note app. Cleve will take care of the rest.",
      icon: "https://framerusercontent.com/images/wYD4FkEYVdJyj4iggLiWmjHTIts.png"
    },
    {
      title: "Hyper-personalised",
      description: "No two Cleve users will get the same content — because your thoughts and ideas are uniquely yours.",
      icon: "https://framerusercontent.com/images/Kkk5FBjwIlEzZ3oA0qJiCfse4U.png"
    }
  ];


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title || "What makes Cleve different"}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid container spacing={4} justifyContent="center" style={{marginTop: '30px'}}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4}  key={index}>
              <Paper className={classes.featureItem}>
                <Avatar alt={feature.title} src={feature.icon} variant="square" style={{width: 48, height: 48}} />
                <Typography variant="h6" style={{fontWeight: 'bold', marginTop: '8px'}} gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body1">
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>


        <Box style={{marginTop: '80px', marginBottom: '30px'}}>
          <Typography variant="h5" style={{fontWeight: 'bold'}} gutterBottom>
            Decide for yourself
          </Typography>
          <Typography variant="body" style={{fontSize: "1rem"}} gutterBottom>
          We asked a few creators for their notes and ran it through ChatGPT and Cleve to compare output quality. Here's the results:
          </Typography>
        </Box>

        

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6"><strong>{item.question}</strong></Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`} style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="body2">
                {item.answer.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </Typography>
              <Box style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px'}}>
                <Card className={classes.firstCard}>
                  <CardHeader title={item.cards[0].title} />
                  <CardContent>
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: item.cards[0].content }} />
                  </CardContent>
                </Card>
                {item.cards.slice(1).map((card, index) => (
                  <Card key={index} style={{marginTop: '20px'}} className={classes.comparisonCard}>
                    <CardHeader title={card.title} />
                    <CardContent className={classes.comparisonCardContent}>
                      <Typography variant="body2" dangerouslySetInnerHTML={{ __html: card.content }} />
                    </CardContent>
                  </Card>
                ))}

              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default ComparisonSection;
