import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, Avatar } from '@material-ui/core';
import { Box, Typography, Button, IconButton } from '@material-ui/core';
import TypingIndicator from './TypingIndicator';
import { useAuth } from "./../util/auth";
import { useMessagesByThread } from '../util/db';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { extractContent } from '../util/chat';



const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '90px'
  },
  message: {
    display: 'flex',
    padding: '10px',
    borderRadius: '12px',
    margin: theme.spacing(1),
    maxWidth: '90%',
    wordBreak: 'break-word',
    width: '100%',
  },
  userMessage: {
    backgroundColor: 'background',
    color: '#111',
    // marginLeft: 'auto',
    // textAlign: 'right',
    // flexDirection: 'row-reverse',
  },
  assistantMessage: {
    backgroundColor: theme.palette.background.default,
    color: '#111',
    marginRight: 'auto',
    textAlign: 'left',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  assistantColor: {
    backgroundColor: theme.palette.primary.main,
  },
  userColor: {
    backgroundColor: theme.palette.background.default,
  },
  avatarImg: {
    objectFit: 'contain'
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '14px',
    padding: '10px'
  },
  threadContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '14px',
    marginBottom: 20,
    padding: '14px 0px'
  },
  showMoreButton: {
    textAlign: 'center', 
    color: '#54A3FF', 
    fontWeight: 'bold', 
    cursor: 'pointer',
  },
  postContainer: {
    backgroundColor: 'white',
    borderRadius: '14px',
    padding: '10px',
    margin: '4px 0px',
    position: 'relative'
  },
  copyButton: {
    position: 'absolute',
    right: '6px',
    bottom: '6px',
    color: '#444'
  },
}));

export function Thread({ thread }) {
  const classes = useStyles();
  
  // State to manage visibility of messages
  const [showAllMessages, setShowAllMessages] = useState(false);

  // Determine the number of messages to display
  const displayedMessages = showAllMessages ? thread.messages : thread.messages.slice(0, 2);

  return (
    <div className={classes.threadContainer}>
      {displayedMessages.map((message, index) => (
        <Message role={message.role} content={message.content} key={index} />
      ))}
      {thread.messages.length > 2 && ( // Only show the button if there are more than 2 messages
        <Box 
          className={classes.showMoreButton}
          onClick={() => setShowAllMessages(!showAllMessages)}
        >
          {showAllMessages ? 'Show Less' : 'Show More'}
        </Box>
      )}
    </div>
  );
}

export function Message({role = 'assistant', content, isLoading, backgroundColor}){
  const classes = useStyles();
  const auth = useAuth();
  const [post, setPost] = useState(null)
  const [title, setTitle] = useState(null)
  console.log(content)

  useEffect(() => {
    // Update post state based on content changes
    const postTitle = extractContent(content, 'between', 'TITLE');
    const generatedPost = extractContent(content, 'between', 'POST');
    if (generatedPost) {
      setPost(generatedPost);
    }
    if (postTitle) {
      setTitle(postTitle);
    }
  }, [content]); // Depend on content to re-run this effect

  const copyToClipboard = () => {
    navigator.clipboard.writeText(post).then(() => {
      // Feedback mechanism (e.g., toast notification) can be implemented here
      console.log('Text copied to clipboard');
    });
  };

  let userName = 'You'
  let userAvatarUrl = auth.user?.photoURL;

  if (auth.user){
    //console.log(auth.user)
    //userName = auth.user.displayName
    userAvatarUrl = auth.user.photoURL;
  }

  // URLs for the user and assistant avatars
    // Replace with user avatar URL
  const assistantAvatarUrl = 'logo.webp'; // Replace with assistant avatar URL

  return (
  <div
    style={{backgroundColor}}
    className={`${classes.message} ${
      role === "assistant" ? classes.assistantMessage : classes.userMessage
    }`}
  >
    <Avatar
      className={classes.avatar}
      classes={{ img: classes.avatarImg }}
      src={role === "assistant" ? assistantAvatarUrl : userAvatarUrl}
      alt={role === "assistant" ? "Assistant" : userName}
    />
    <div className={`${classes.messageContent} ${role === "assistant" ? classes.assistantColor : classes.userColor}`}>
      {/* <strong>{role === "assistant" ? "Cleve" : userName}</strong> */}
      { post ?
        <div style={{whiteSpace: 'pre-wrap'}}>
          {extractContent(content, 'before', "TITLE")}
            <div className={classes.postContainer}>
              {title && <div><strong>{title}</strong></div>}
              {extractContent(content, 'between', 'POST')}
              <IconButton 
                className={classes.copyButton}
                onClick={copyToClipboard}
                size="small">
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </div>
          {extractContent(content, 'after')}
        </div>
      :
      <div style={{whiteSpace: 'pre-wrap'}}>
        {content}
      </div>
      }
      {isLoading && <TypingIndicator/>}
    </div>
  </div>
  )
}

export default function Messages({ isLoading, messages, threads }) {
    const classes = useStyles();
    const messagesEndRef = useRef(null);
    
  
    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);
  
    return (
      <div className={classes.container}>
        { 
          threads && threads.slice(0).reverse().map((thread) => (
              <Thread thread={thread} key={thread.id}/>
          ))
        }
        {messages.map((msg, index) => (
          <Message role={msg.role} content={msg.content} title={msg.title} key={index}/>
        ))}
        { isLoading && <Message role="assistant" isLoading={isLoading} key="loading"/> }
        <div ref={messagesEndRef} />
      </div>
    );
  }