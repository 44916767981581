import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { toTimestamp, updatePost } from '../util/db';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useDarkMode } from '../util/theme';
import analytics from '../util/analytics';

const ScheduleDialog = ({ open, onClose, note }) => {
  const darkMode = useDarkMode();
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    const date = note?.scheduledAt ? note.scheduledAt.toDate() : new Date();
    const tzoffset = date.getTimezoneOffset() * 60000;
    const localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, 16);
    setSelectedDateTime(localISOTime);
  }, [note]);

  const handleDateTimeChange = (event) => {
    setSelectedDateTime(event.target.value);
  };

  const handleSchedule = async () => {
    const toastId = toast.loading('Scheduling post...');
    analytics.track('schedulePost', {postId: note.id, scheduledAt: selectedDateTime})
    await updatePost({id: note.id, status: 'Scheduled', scheduledAt: toTimestamp(new Date(selectedDateTime))});
    queryClient.invalidateQueries({queryKey: ['posts', note.owner]});
    toast.update(toastId, { render: "Post scheduled", type: "success", isLoading: false, autoClose: 2000 });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Schedule Post</DialogTitle>
      <DialogContent>
        <DialogContentText>
          *This will not post directly to your account.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="datetime-local"
          label="Select Date & Time"
          type="datetime-local"
          fullWidth
          variant="outlined"
          style={{colorScheme: darkMode.value ? 'dark' : 'light'}}
          InputLabelProps={{
            shrink: true,
          }}
          value={selectedDateTime}
          onChange={handleDateTimeChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSchedule} color="primary">
          Schedule
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleDialog;