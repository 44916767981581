import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { toTimestamp, updatePost } from '../util/db';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Paper, Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions } from '@material-ui/core';
import { parseClassNames } from '@fullcalendar/core/internal';
import dayjs from 'dayjs';
import Post from './Post';

const eventColors = {
  'linkedin': '#0077B5',
  'tweet': '#000000',
  'script': '#EE1D52',
  'thread': '#999999',
  'instagram': '#F77737'
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ':root': {
      '--fc-button-bg-color': theme.palette.secondary.main,
      '--fc-button-border-color': theme.palette.secondary.main,
      '--fc-button-text-color': 'white',
      '--fc-button-active-bg-color': theme.palette.primary.main,
      '--fc-button-active-border-color': theme.palette.primary.main,
    },
  },
}));

const CalendarView = (props) => {

  const queryClient = useQueryClient();
  const classes = useStyles()

  const {data, fetchNextPage, hasNextPage, isLoading, isError} = props

  const [events, setEvents] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    if (!isLoading && data && data.pages) {
          const newEvents = data.pages.flatMap(page =>
              page.items.flatMap(note => note.scheduledAt ? [{
                  id: note.id,
                  title: note.content ? note.content.replace(/<[^>]+>/g, '').substring(0, 40) + '...' : 'No Content', // Strip HTML tags
                  start: note.scheduledAt.toDate(),
                  backgroundColor: eventColors[note.type],
                  extendedProps: {
                      note: note,
                      content: note.content,
                      status: note.status,
                      tags: (note.tags ?? []).map(tag => tag.label).join(', ')
                  }
              }] : [])
          );
          setEvents(newEvents);
      }
  }, [data, isLoading]);

  const handleEventClick = ({ event }) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEventReceive = async (info) => {
    console.log('Event Received:', info.event.title);
  };

  const handleEventChange = async (info) => {
    console.log('Event changed:', info.event.title);
    const toastId = toast.loading('Scheduling post...');
    await updatePost({id: info.event.id, status: 'Scheduled', scheduledAt: toTimestamp(info.event.start)});
    queryClient.invalidateQueries({queryKey: ['posts', info.event.extendedProps.note.owner]});
    toast.update(toastId, { render: `Post scheduled to ${dayjs(info.event.start).format('D MMMM')}`, type: "success", isLoading: false, autoClose: 2000 });
  };

  const handleDateClick = arg => {
    const newEvent = {
        title: 'New Event',
        start: arg.date,
        id: events.length + 1
    };
    setEvents([...events, newEvent]);
  };

  return (
    <Paper style={{ padding: 10 }}>
      <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          events={events}
          select={handleDateClick}
          eventReceive={handleEventReceive}
          eventClick={handleEventClick}
          eventChange={handleEventChange}
      />
      {selectedEvent && (
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <Post note={selectedEvent.extendedProps.note} />
                </Dialog>
            )}
    </Paper>
  );
};

export default CalendarView;