import React, { useEffect, useState } from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import {
  Stepper,
  MobileStepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Chip,
  Typography,
  Grid,
  Container,
  makeStyles,
  Box,
  CircularProgress,
  LinearProgress,
  Fade,
  useMediaQuery,
  useTheme,
  Section
} from '@material-ui/core';
import { useAuth } from '../util/auth';

function VerifyEmailPage(props) {
  const router = useRouter();
  const [resendTimer, setResendTimer] = useState(0);
  const auth = useAuth();

  useEffect(() => {
    console.log(resendTimer)
    if (resendTimer > 0) {
      setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
    }
  }, [resendTimer]);

  const handleResend = () => {
    if (auth) {
      auth.sendVerificationEmail();
      setResendTimer(30);

    }

  }

  return (
    <>
      <Meta title="Auth" />
      <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '120px' }}>
        <h1>Verify your email</h1>
        {/* <Fade in={true} timeout={1000}>
          <img src="/logo.webp" alt="Cleve Logo" style={{width: '100px', height: '100px', padding: '15px', marginBottom: '20px'}}/>
          </Fade> */}
        <Typography style={{ textAlign: 'center', fontSize: '1.05rem' }} variant="body">We sent an email to <br /> {auth?.user?.email}. <br /> Click the link inside to get started.</Typography>
        <Button variant="text" color="primary" onClick={handleResend} disabled={resendTimer > 0} style={{ marginTop: '20px' }}>Resend verification email</Button>
        {resendTimer > 0 && <Typography style={{ textAlign: 'center' }} variant="caption" >Wait {resendTimer} seconds to try again</Typography>}
      </Container>
    </>
  );
}

export default VerifyEmailPage;
