import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import { Link } from "../util/router";
import LongContent from "./LongContent";

function AboutSection(props) {
  const data = {
    domain: "cleve.ai",
    companyName: "Cleve",
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <LongContent>
      <h1 style={{textAlign: 'center'}}>About Cleve</h1>
      <p>an AI assistant helping creators scale their online presence..</p>
      </LongContent>
      </Container>
    </Section>
  );
}

export default AboutSection;
