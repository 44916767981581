import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Tooltip,
  Button,
  Modal,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppIcon from '@material-ui/icons/GetApp';
import { deleteNote, updateNote, updateUser } from '../util/db';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import TagManagementDialog from './TagManagementDialog';
import analytics from '../util/analytics';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(0),
        backgroundColor: theme.palette.background.default,
        boxShadow: 'none',
      },
      actionArea: {
        // You can define styles for the clickable area if necessary
      },
      content: {
        position: 'relative',
      },
      noteContent: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingTop: '35px',
        maxHeight: '160px', /* Adjust based on your requirement */
        transition: 'max-height 0.3s ease',    
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 50,
          left: 0,
          right: 0,
          height: '70%',
          background: `linear-gradient(to bottom, transparent, ${theme.palette.background.default})`, /* Adjust the color to match your background */
          PointerEvent: 'none', /* Prevents the pseudo-element from being clickable */
        },
        '& h1': {
          marginBottom: '2px',
        },
        '& p': {
          margin: '0 0 0.7rem 0'
        }
      },
      
      expanded: {
        maxHeight: 'none',
    
        '&::before': {
          display: 'none' /* Hide the fading effect when expanded */
        },
      },
    
      seeMoreButton: {
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        opacity: 0.7,
        '&:hover':{backgroundColor: 'transparent'}
      },
    
      date: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        color: 'gray',
        fontSize: '2 rem'
      },
      tags: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        '& > *': {
          margin: theme.spacing(0.5),
        },
      },
      chip: {
        marginRight: theme.spacing(1),
        zIndex: 1
      },
      optionsButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 2, // ensure the button is above other elements
      },
      editButton: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(6),
        zIndex: 1
      },
      imagesWrapper: {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        overflowX: 'auto',
      },
      imageContainer: {
        position: 'relative',
        '&:hover .imageActions': {
          opacity: 1,
          backgroundColor: 'rgba(255, 255, 255, 1)',
        },
      },
      uploadedImage: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        borderRadius: theme.spacing(1),
        cursor: 'pointer',
      },
      imageActions: {
        position: 'absolute',
        top: theme.spacing(0.2),
        right: theme.spacing(0.2),
        opacity: 0.7,
        width: '30px',
        height: '30px',
        transition: 'opacity 0.2s',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '50%',
      },
      imageModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      modalImage: {
        maxWidth: '90%',
        maxHeight: '90vh',
      },
}));

const NoteCard = ({ note, setCurrentNote }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [expandedNotes, setExpandedNotes] = useState({});
  const auth = useAuth();
  const router = useRouter();
  const queryClient = useQueryClient();
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageMenuAnchorEl, setImageMenuAnchorEl] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteImagesFromStorage = async (images) => {
    if (!images || images.length === 0) return;

    console.log('Deleting images:', images);
    const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
    
    // Delete each image
    const deletePromises = images.map(imageUrl => 
      fetch('/api/upload', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ 
          imageUrl,
          userId: auth?.user?.id
        }),
      })
    );

    try {
      await Promise.all(deletePromises);
    } catch (error) {
      console.error('Error deleting images:', error);
      // Continue with note deletion even if image deletion fails
    }
  };

  const handleDelete = async () => {
    console.log('Delete note with id:', note.id);
    analytics.track('deleteNote', { noteId: note.id });
    
    try {
      // Delete images first
      if (note.images) {
        await deleteImagesFromStorage(note.images);
      }
      
      // Then delete the note
      await deleteNote(note.id);
      queryClient.invalidateQueries('notes');
      handleClose();
      toast.success('Note deleted');
    } catch (error) {
      console.error('Error deleting note:', error);
      toast.error('Failed to delete note');
    }
  };

  const editNote = () => {
    analytics.track('editNote', { noteId: note.id });
    handleClose();
    setCurrentNote(note);
    router.push(`/notes/${note.id}`, { note: note });
  };

  const handleChipClick = (tag) => {
    analytics.track('clickTag', { tag: tag });
    const searchParams = new URLSearchParams(router.location.search);
    searchParams.set('tags', tag);
    const newSearchString = searchParams.toString();
    if (router.location.search !== `?${newSearchString}`) {
      router.push(`${router.pathname}?${newSearchString}`);
    }
  };

  const updateTags = async (tags) => {
    const globalTags = auth.user?.tags || [];
    const newTags = tags.filter(tag => !globalTags.includes(tag));
    if (newTags.length > 0) {
      await updateUser(auth.user.id, { tags: [...new Set([...globalTags, ...tags])] });
    }
    await updateNote({ id: note.id, tags: tags });
    queryClient.invalidateQueries({ queryKey: ['notes', auth.user.id] });
    toast.success('Tags updated');
  };

  const toggleNoteExpansion = () => {
    setExpandedNotes((prev) => ({
      ...prev,
      [note.id]: !prev[note.id]
    }));
  };

  const shouldTruncate = () => {
    return note.content.length > 200;
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const handleImageMenuOpen = (event, index) => {
    event.stopPropagation();
    setImageMenuAnchorEl(event.currentTarget);
    setActiveImageIndex(index);
  };

  const handleImageMenuClose = () => {
    setImageMenuAnchorEl(null);
    setActiveImageIndex(null);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography variant="body1" component="p" className={classes.date}>
            {new Date(note.createdAt.seconds * 1000).toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}
          </Typography>
          
          <Tooltip title="Edit note" aria-label="edit">
            <IconButton
              className={classes.editButton}
              onClick={editNote}
              size="small">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            aria-label="more"
            aria-controls="note-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.optionsButton}
          >
            <MoreVertIcon />
          </IconButton>
          <Typography
            className={`tiptap ${classes.noteContent} ${expandedNotes[note.id] || !shouldTruncate() ? classes.expanded : ''}`}
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{ __html: note.content }}
          />
          {shouldTruncate() &&
            <Button
              disableRipple
              className={classes.seeMoreButton}
              variant="text" color="default" onClick={toggleNoteExpansion}>
              <Typography style={{ fontWeight: 'bold' }}>{expandedNotes[note.id] ? '...see less' : '...see more'}</Typography>
            </Button>
          }
          {note.images && note.images.length > 0 && (
            <div className={classes.imagesWrapper}>
              {note.images.map((image, index) => (
                <div key={index} className={classes.imageContainer}>
                  <img
                    src={image}
                    alt={`Note ${index + 1}`}
                    className={classes.uploadedImage}
                    onClick={() => handleImageClick(image)}
                  />
                  <IconButton
                    className={`imageActions ${classes.imageActions}`}
                    onClick={(e) => handleImageMenuOpen(e, index)}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </div>
              ))}
            </div>
          )}
          <div className={classes.tags}>
            <Chip
              key={'status'}
              color={note.status?.toLowerCase() === 'idea' ? 'default' : 'primary'}
              label={note.status}
              className={classes.chip}
            />
            {note.tags && note.tags.map((tag, index) => (
              <Chip
                key={index}
                variant='outlined'
                label={tag}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChipClick(tag);
                }}
                className={classes.chip}
              />
            ))}
            <Chip
              key={'addTag'}
              label="Tag"
              color='default'
              variant='outlined'
              onClick={() => setTagDialogOpen(true)}
              onDelete={() => setTagDialogOpen(true)}
              deleteIcon={<AddIcon />}
              className={classes.chip}
            />
          </div>
        </CardContent>
      </Card>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        className={classes.imageModal}
      >
        <img
          src={selectedImage}
          alt="Full size"
          className={classes.modalImage}
          onClick={() => setModalOpen(false)}
        />
      </Modal>
      <Menu
        anchorEl={imageMenuAnchorEl}
        open={Boolean(imageMenuAnchorEl)}
        onClose={handleImageMenuClose}
      >
        <MenuItem onClick={() => {
          const image = note.images[activeImageIndex];
          const link = document.createElement('a');
          link.href = image;
          link.download = `image-${Date.now()}.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          handleImageMenuClose();
        }}>
          <GetAppIcon fontSize="small" style={{ marginRight: 8 }} />
          Download
        </MenuItem>
      </Menu>
      <TagManagementDialog
        isOpen={tagDialogOpen}
        handleClose={() => setTagDialogOpen(false)}
        selectedTags={note.tags || []}
        updateTags={updateTags}
      />
      <Menu
        id="note-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={editNote}>
          <EditIcon fontSize="small" style={{ marginRight: 8 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} style={auth?.user?.email === 'tia@cleve.ai' ? { position: 'fixed', top: 0, opacity: 0.05 } : {}}>
          <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default NoteCard;