"use client";
import ChatSection from "../components/ChatSection";
import { Box, Button, ButtonGroup, Paper, Tab, Tabs, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from '@material-ui/core/Snackbar';
import { useChat } from "ai/react";
import React, { useState, useEffect }from "react";
import Meta from "../components/Meta";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth";
import { Link, useRouter } from "./../util/router";
import ChatIcon from '@material-ui/icons/ForumOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Chat from "../components/Chat";
import { usePagedPostsByOwner } from "../util/db";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 1000, // Ensure it stays on top of other content
    backgroundColor: '#fff', // Optional: Set background color to match your theme
  },
  tabsContainer: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '800px', // Adjust based on your preference
      margin: 'auto',
    }
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box> {/* Padding around the content */}
          {children}
        </Box>
      )}
    </div>
  );
}

function ChatPage(props) {
  document.title = "Chat - Cleve";
  const classes = useStyles();
  const auth = useAuth()
  const router = useRouter();
  //const {data: posts, isLoading} = usePosts()

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    console.log(auth.user.name)
    if (auth.user && !auth.user.questions){
      router.push('/onboarding')
    }
  }, [auth]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const {data: posts, fetchNextPage, hasNextPage, isLoading, isError} = usePagedPostsByOwner(auth?.user?.id)
  console.log(posts)

  return (
    <Box style={{ backgroundColor: '#f3f3f3'}}> {/* paddingTop should be equal or greater than the height of your tabs to prevent content from being hidden behind the sticky tabs */}
      <Meta title="Chat with Cleve" />
      {router.query.paid &&
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal:'center'}}>
        <Alert onClose={handleClose} severity="success">
          { auth.user?.planIsActive && !router.query.credit ? `You are now subscribed to the ${auth.user?.planId} plan 🥳`: ''}
          { router.query.credit ? `You successfully bought ${router.query.credit} credit!` : ''}
        </Alert>
      </Snackbar>
      }
      <Paper square className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
          className={classes.tabsContainer}
          variant="fullWidth"
        >
          <Tab icon={<ChatIcon />} aria-label="chat" />
          <Tab icon={<PostAddIcon />} aria-label="post" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <ChatSection />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {/* Content for the Post tab */}
        {/* Insert the content or component for posts here */}
        {
          isLoading?
            "Loading"
            :
            <Chat
              messages={posts.pages.map(page => page.items).flat().map(post => ({ 
                ...post, 
                content: (post.title? `<TITLE>${post.title}<TITLE>` : '') + `<POST>${post.content}<POST>` 
              }))}
            />
        }
        
      </TabPanel>
      
    </Box>
  );
}

export default requireAuth(ChatPage);