import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Button } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import analytics from '../util/analytics';

const SortMenu = ({ selectedSortBy, onSortChange, properties }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const sortOptions = properties || [
    { label: 'Last Created', value: 'createdAt' },
    { label: 'Last Updated', value: 'updatedAt' },
    { label: 'Schedule Date', value: 'scheduledAt' },
  ];

  const handleMenuOpen = (event) => {
    analytics.track('openSortMenu');
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button 
        startIcon={<SortIcon />}
        onClick={handleMenuOpen}
        size="small"
        style={{textTransform: 'none'}}
      >
        Sort by {sortOptions.find(option => option.value === selectedSortBy)?.label || ''}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {sortOptions.map((option) => (
          <MenuItem 
            key={option.value} 
            selected={option.value === selectedSortBy}
            onClick={() => {
              onSortChange(option.value);
              handleMenuClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};


export default SortMenu;