import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { useAuth } from '../util/auth';
import { Link, useRouter } from "./../util/router";

const pricingLink = {
    'monthly': '/purchase/plus',
    'yearly': '/purchase/premium'
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    zIndex: 99,
  },
  title: {
    padding: 0,
    marginBottom: theme.spacing(1),
    '& h2': {
      fontWeight: 600,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  buttonGroup: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  button: {
    width: '50%',
    border: '2px solid #c0c0c088',
    borderRadius: 4,
    textTransform: 'none',
    fontSize: '0.875rem',
    padding: theme.spacing(1),
    '&:first-child': {
      marginRight: theme.spacing(1),
    },
    '&.selected': {
      borderColor: theme.palette.primary.main,
    },
  },
  saveText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },
  listItem: {
    padding: 0,
  },
  checkIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
  },
  price: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  upgradeButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: 'none',
    },
  },
}));

const PricingModal = ({ open, onClose }) => {
  const classes = useStyles();
  const [billingCycle, setBillingCycle] = useState('yearly');
  const auth = useAuth()
  const router = useRouter()

  const handleBillingCycleChange = (newBillingCycle) => {
    setBillingCycle(newBillingCycle);
  };

  const price = billingCycle === 'monthly' ? 29 : 20;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className={classes.root}>
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h5" component="h2">
            Write better content faster
          </Typography>
        </DialogTitle>
        <Typography variant="body1" className={classes.subtitle}>
          Want to write more personalized content? Go Pro now.
        </Typography>

        <Box className={classes.buttonGroup}>
          <Button
            className={`${classes.button} ${billingCycle === 'monthly' ? 'selected' : ''}`}
            onClick={() => handleBillingCycleChange('monthly')}
            variant="outlined"
          >
            Monthly
          </Button>
          <Button
            className={`${classes.button} ${classes.yearlyButton} ${billingCycle === 'yearly' ? 'selected' : ''}`}
            onClick={() => handleBillingCycleChange('yearly')}
            variant="outlined"
          >
            Yearly <span className={classes.saveText}>SAVE 30%</span>
          </Button>
        </Box>

        <Typography variant="h6">What you get</Typography>
        <List>
          {[
            'Unlimited AI generated posts',
            'Unlimited AI Editing', 
            'Lifetime Updates'
        ].map((item) => (
            <ListItem key={item} className={classes.listItem}>
              <ListItemIcon className={classes.checkIcon}>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>

        <Typography variant="h4" className={classes.price}>
          ${price.toFixed(2)}
          <Typography variant="caption">/{billingCycle === 'monthly' ? 'month' : 'month'}</Typography>
        </Typography>

        <Button
          variant="contained"
          fullWidth
          className={classes.upgradeButton}
          disableElevation
          component={Link}
          to={`${auth.user? '' : '/auth/signup?next='}${pricingLink[billingCycle]}`}
        >
          Upgrade to Pro
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default PricingModal;