"use client";
import Chat from "./Chat";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import { useChat } from "ai/react";
import React, { useState, useEffect }from "react";
import Meta from "./Meta";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth";
import { Link, useRouter } from "./../util/router";
import { createMessage, createPost, usePagedThreads, useMessagesByThread, fetchPagedMessages } from "../util/db";
import { getRandomQuestion, extractContent } from "../util/chat";

function ChatSection(props) {
  document.title = "Chat - Cleve";
  const auth = useAuth();
  const [userName, setUserName] = useState(null);
  const [threads, setThreads] = useState(null);
  const [question, setQuestion] = useState(getRandomQuestion());
  const [threadId, setThreadId] = useState(null);
  const [response, setResponse] = useState(false);
  const [threadMessages, setThreadMessages] = useState({});

  

  const onFinish = (message) => { 
    console.log('onFinish')
    setResponse(message)
  }

  // {data, fetchNextPage, hasNextPage, isLoading, isError}
  const pagedThreads = usePagedThreads(auth.user.id)

  //console.log(pagedThreads.fetchNextPage())

  const { 
    messages, input, append, handleInputChange, handleSubmit, isLoading, setMessages 
  } = useChat({
    onFinish, 
    body: {user: auth.user},
    headers: {
      Authorization: `Bearer ${auth?.user?.accessToken}`
    }
  });
  

  const handleMessageSubmit = async (event) => {
    event.preventDefault();
    const userId = auth.user.id;
    let newThreadId = threadId
    if(question) {
      // Simplify message creation logic and fix promise handling.
      let questionMessage = {
        role: 'assistant',
        content: question,
        contentType: 'text',
      };
      
      console.log({userId, threadId, questionMessage});
      let message = await createMessage(userId, null, questionMessage);
      console.log(message.threadId);
      newThreadId = message.threadId
      setThreadId(message.threadId);
      
      setMessages((prevData) => [...prevData, questionMessage]);

      
      //append(userMessage); // Uncommented to use append from useChat.
      setQuestion(null)
    }

    let userMessage = {
      role: 'user',
      content: input,
      contentType: 'text',
    };
    let message = await createMessage(userId, newThreadId, userMessage);
    setThreadId(message.threadId);
    //console.log({userId, message.threadId, userMessage});
    handleSubmit(event); // Ensure handleSubmit is correctly implemented to not duplicate message submission logic.
  };

  
  const handleQuestion = () => {
    setQuestion(getRandomQuestion())
  }

  useEffect(() => {
    if (auth.user && pagedThreads.data){
      const fetchThreadMessages = async () => {
        let messages = [];
        
        // Assuming pagedThreads is an array of objects, each with a 'threads' array
        for (const page of pagedThreads.data.pages) {
          for (const thread of page.threads) {
            // Here, you simulate the `useMessagesByThread` logic within useEffect
            // Replace this with your actual data fetching logic
            const {messages: threadMessages} = await fetchPagedMessages(thread.id, {})();
            thread.messages = threadMessages
            messages.push(thread);
          }
        }
  
        setThreads(messages);
      };
  
      fetchThreadMessages();
      //setThreads(pagedThreads.data)
      //console.log(auth.user)
      if (messages.length < 1){
        setMessages([
          {'role': 'assistant', 'content': `Hello ${auth.user.displayName}! I'm Cleve, your personal content assistant. I will help you make personalized content by asking you several questions. Feel free to click the 🔃 icon to change the question.`}
        ])
      }
    }
  }, [pagedThreads.data, auth]);

  useEffect(() => {
    const saveMessage = async () => {
        //let lastMessage = messages[messages.length - 1];
        let { threadId: newThreadId, messageId } = await createMessage(auth.user.id, threadId, response);
        setThreadId(newThreadId)

        const post = extractContent(response.content, 'between', 'POST')
        const title = extractContent(response.content, 'between', 'TITLE')
        if(post){
          console.log('POST', post)
          await createPost(auth.user.id, newThreadId, messageId, {'content': post, title: title});
        }
        setResponse(null)
    }

    if(response){      
      saveMessage();
    }
  }, [response]);

  return (
      <Chat
          isMobile={props.isMobile}
          question={question}
          setQuestion={setQuestion}
          handleQuestion={handleQuestion}
          input={input}
          handleInputChange={handleInputChange}
          handleMessageSubmit={handleMessageSubmit}
          messages={messages}
          threads={threads}
          isLoading={isLoading}
        />
  );
}

export default requireAuth(ChatSection);


  // useEffect(() => {
  //   if(pagedThreads.data) {
  //     console.log(pagedThreads.data)
  //     allThreads = []
  //     pagedThreads.data.pages.forEach(page => {
  //       allThreads.push(...page.threads);
  //     });
  //     console.log(allThreads)
  //     setThreads(allThreads)
  //   }
  // }, [pagedThreads.data]);