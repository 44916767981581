import React from 'react';
import { Container, TextField, IconButton, Paper, makeStyles, InputAdornment, Box } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Messages, {Message} from './Messages';
import { useAuth } from "./../util/auth";


const useStyles = makeStyles((theme) => ({
  chatContainer: {
    //maxHeight: '600px',
    //height: '900px',
    width: '90%',
    overflow: 'auto',
    padding: 0,
    paddingBottom: '50vh',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    //backgroundColor: 'grey',
  },
  inputField: {
    width: '600px',
    maxWidth: '90vw',
    backgroundColor: 'theme.palette.background.default',
  },
  inputContainer: {
    //borderTop: 'solid 1px #ccc',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    padding: '0px',
    bottom: '0px',
    left: '0px',
    width: '100vw',
    paddingBottom: '20px',
  },
  questionContainer: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px #aaa',
    //padding: '14px',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    width: '90%',
    maxWidth: '600px',
    borderRadius: '20px'

  }
}));

const Chat = ({ isMobile, input, question, setQuestion, handleQuestion, handleInputChange, handleMessageSubmit, messages, threads, isLoading }) => {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <Container className={classes.chatContainer} maxWidth="sm">
        <Messages threads={threads} messages={messages} isLoading={isLoading} />
      <Paper className={classes.inputContainer}>
        { question &&
        <Paper class={classes.questionContainer}>
          <Message content={question} backgroundColor={'transparent'} />
          <IconButton onClick={() => setQuestion(null)} color="primary">
            <CloseIcon />
          </IconButton>
        </Paper>
        }
        
        <form onSubmit={handleMessageSubmit}>
          <TextField
            label="Type a message"
            type="text"
            variant="outlined"
            className={classes.inputField}
            multiline = {true}
            value={input}
            onChange={handleInputChange}
            margin="normal"
            InputProps={{
              style: {
                borderRadius: "40px",
                backgroundColor: 'white'
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleQuestion} color="primary">
                    <AutorenewIcon />
                  </IconButton>
                  <IconButton type="submit" color="primary">
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Paper>
    </Container>
  );
};

export default Chat;