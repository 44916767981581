import React from "react";
import { Box, Typography,List,ListItem} from "@material-ui/core";

import LongContent from "./LongContent";

function LegalPrivacy(props) {
document.title = "Privacy Policy - Cleve";

  return (
    <Box my={2}sx={{mt:"2px"}}>
       
    <Box
      sx={{
        width: 'inherit',
        display: 'flex',
        px:"2px",
        mb:"1.6rem",
        
        backgroundColor:"rgba(0, 0, 0, 0)",
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h4"gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'bold',
        }}
      >
Privacy policy
      </Typography>
      <Typography
gutterBottom
        variant="body2"
        sx={{
          color: 'grey.500',
          fontWeight: 'medium',
          textAlign: 'right', // Align text to the right

          
        }}
      >
        Effective date: 28/03/2024
      </Typography>

      <h2><strong>Introduction</strong></h2>
      <p>Welcome to Cleve! Your privacy is of paramount importance to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our services.</p>

      <h2><strong>1. Information Collection</strong></h2>
      <p><strong>1.1 Personal Information:</strong> We collect information that personally identifies you, such as your name, email address, and LinkedIn profile, when you register for an account or interact with our services.</p>
      <p><strong>1.2 Usage Information:</strong> We gather data related to how you use Cleve, including the types of content you generate and your engagement with the service.</p>
      <p><strong>1.3 Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance your experience, understand user activity, and improve the quality of our services.</p>

      <h2><strong>2. Use of Information</strong></h2>
      <p><strong>2.1 Service Provision:</strong> Your information helps us deliver, personalize, and improve our services, ensuring an optimal and customized user experience.</p>
      <p><strong>2.2 Communication:</strong> We may use your information to communicate with you about service updates, offers, and promotional content that may be of interest to you.</p>
      <p><strong>2.3 Improvement and Development:</strong> We analyze user information to enhance Cleve, develop new features, and ensure the effectiveness of our service.</p>

      <h2><strong>3. Sharing and Disclosure</strong></h2>
      <p><strong>3.1 Service Providers:</strong> We share information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and customer service, under confidentiality agreements.</p>
      <p><strong>3.2 Legal and Safety Reasons:</strong> We may disclose your information if required by law or if we believe it is necessary to protect the safety, rights, or property of Cleve, our users, or the public.</p>
      <p><strong>3.3 Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, user information may be transferred as a business asset.</p>

      <h2><strong>4. International Data Transfers</strong></h2>
      <p>Your information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction, where the data protection laws may differ.</p>

      <h2><strong>5. Data Security</strong></h2>
      <p>We implement reasonable security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.</p>

      <h2><strong>6. Your Rights</strong></h2>
      <p>You have the right to access, update, or delete your personal information at any time. Additionally, you may opt-out of receiving promotional communications from us.</p>

      <h2><strong>7. Children’s Privacy</strong></h2>
      <p>Cleve is not intended for individuals under the age of 18. We do not knowingly collect personal information from children without parental consent.</p>

      <h2><strong>8. Changes to This Privacy Policy</strong></h2>
      <p>We may update this policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

      <h2><strong>9. Contact Us</strong></h2>
      <p>If you have any questions about this Privacy Policy, please contact us at support@cleve.ai.</p>

    </Box>

    </Box>
  );
}

export default LegalPrivacy;
