import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  document.title = "Pricing - Cleve's Plans and Packages";

  return (
    <>
      <Meta title="Pricing - Cleve.ai" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Affordable Plans"
      />
    </>
  );
}

export default PricingPage;
