import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { updatePost , createPost } from '../util/db';
import { POST_TYPE } from '../util/enums';
import analytics from '../util/analytics';
import { useQueryClient, useMutation } from 'react-query';
import { useAuth } from '../util/auth';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: '0px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  }
}));

const ImportPostDialog = ({ open, handleClose }) => {
  const classes = useStyles();
  const auth = useAuth()
  const queryClient = useQueryClient()

  const [formData, setFormData] = useState({
    date: '',
    type: '',
    content: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    console.log(formData); // Here you would handle the submission e.g., sending to an API
    const toastId = toast.loading("Importing post...")
    let postData = {
      type: formData.type,
      content: formData.content,
      owner: auth.user.id,
      status: 'Imported'
    }
    await createPost(postData)
    setFormData({
      date: '',
      type: '',
      content: ''
    });
    setTimeout(() => {
      queryClient.invalidateQueries({queryKey: ['posts', auth.user.id]})
    }, 2000)
    toast.update(toastId, { render: "Post Imported", type: "success", isLoading: false, autoClose: 2000 });
    analytics.track('importPost')
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Import Post</DialogTitle>
      <DialogContent style={{minWidth: '200px', width: '500px', maxWidth: '80vw'}}>
        {/* <TextField
          label="Date"
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        /> */}
        <TextField
          select
          label="Type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          fullWidth
        >
          { Object.keys(POST_TYPE).map((type) => (
            <MenuItem key={POST_TYPE[type].id} value={POST_TYPE[type].id}>{POST_TYPE[type].label}</MenuItem>
          ))
          }
        </TextField>
        <TextField
          style={{marginTop: '20px'}}
          label="Content"
          name="content"
          value={formData.content}
          onChange={handleChange}
          fullWidth
          multiline
          placeholder='Paste your previous writing here...'
          //variant="outlined"
          rows={4}
          maxRows={12}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportPostDialog;