import React from "react";
import ReactDom from "react-dom";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

// usetiful script
(function (w, d, s) {
  var a = d.getElementsByTagName('head')[0];
  var r = d.createElement('script');
  r.async = 1;
  r.src = s;
  r.setAttribute('id', 'usetifulScript');
  console.log('Usetiful')
  r.dataset.token = "2a0a4f3a2993f0d165751469625bb581";
                      a.appendChild(r);
})(window, document, "https://www.usetiful.com/dist/usetiful.js")

// (function (w, d, s) {
//   var a = d.getElementsByTagName('head')[0];
//   var r = d.createElement('script');
//   r.async = 1;
//   r.src = s;
//   r.setAttribute('id', 'promotekitScript');
//   console.log('Promotekit')
//   r.dataset.promotekit = "252f5a29-9802-4d7d-9739-07d5052aae50";
//                       a.appendChild(r);
// })(window, document, "https://cdn.promotekit.com/promotekit.js")

Sentry.init({
    dsn: "https://86221320a016b6d47e5275d4a667eb39@o4506964049854464.ingest.us.sentry.io/4506964052606976",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost", 
        /^https:\/\/notes\.cleve\.ai\/api/ // Adjusted to match your application's API endpoint
      ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

ReactDom.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
