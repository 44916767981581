import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import { Link } from "../util/router";
import LongContent from "./LongContent";

function ContactSection(props) {
  const data = {
    domain: "company.com",
    companyName: "Company",
    email: "",
    phone: "",
    address: ""
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <LongContent>
        <h1>Get in Touch</h1>
        <p>Have custom needs for your enterprise? </p>
        <p>Contact us at support@cleve.ai</p>
      </LongContent>
      </Container>
    </Section>
  );
}

export default ContactSection;
