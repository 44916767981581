"use client";
import { Box, Button, Chip, makeStyles, BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import React, { useState, useEffect }from "react";
import Meta from "../components/Meta";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth";
import { Link, useRouter } from "./../util/router";
import Tiptap from "../components/TipTap";
import NoteList from "../components/NoteList";
import NoteNav from "../components/NoteNav";
import SortMenu from "../components/SortMenu";
import FilterMenu from "../components/FilterMenu";
import { usePagedNotesByOwner } from "../util/db";
import { POST_TYPE } from "../util/enums";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    position: 'sticky',
    width: '100%',
    top: 0,
    boxShadow: 'none',
    color: theme.palette.text.primary,
    zIndex: 1000, // Ensure it stays on top of other content
    backgroundColor: theme.palette.background.default, // Optional: Set background color to match your theme
  },
  container: {
    maxWidth: '800px',
    margin: 'auto',
    backgroundColor: theme.palette.background.default
  },
  root: {
    position: 'fixed',
    width: '600px',
    maxWidth: '100%',
    left: 0,
    bottom: 0,
  },
  // tabsContainer: {
  //   [theme.breakpoints.up('md')]: {
  //     maxWidth: '800px', // Adjust based on your preference
  //     margin: 'auto',
  //   }
  // },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box> {/* Padding around the content */}
          {children}
        </Box>
      )}
    </div>
  );
}

function NoteListPage(props) {
  document.title = "Cleve - Notes";
  const classes = useStyles();
  const auth = useAuth()
  const router = useRouter();
  //const {data: posts, isLoading} = usePosts()

  const [value, setValue] = useState(0);
  const [currentNote, setCurrentNote] = useState(null);
  const [open, setOpen] = useState(true);
  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState('createdAt');

  const sortProperties = [
    { label: 'Last Created', value: 'createdAt' },
    { label: 'Last Updated', value: 'updatedAt' }
  ]
  const properties = ['tags', 'status']; 
  const values = {
    type: Object.values(POST_TYPE).map(item => item.id),
    status: ['Idea', 'Generated']
  };

  const updateFilters = (newFilters, newSortBy = sortBy) => {
    setFilters(newFilters);
    setSortBy(newSortBy);

    const searchParams = new URLSearchParams();

    Object.entries(newFilters).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        searchParams.set(key, value);
      }
    });

    if (newSortBy) {
      searchParams.set('sortBy', newSortBy);
    }

    const newSearchString = searchParams.toString();
    router.push({search: newSearchString});
  };

  const handleApplyFilter = (filter) => {
    const newFilters = { ...filters, [filter.property]: filter.value };
    updateFilters(newFilters);
  };

  const handleDeleteFilter = (property) => {
    const { [property]: _, ...newFilters } = filters;
    updateFilters(newFilters);
  };

  const handleSortChange = (newSortBy) => {
    updateFilters(filters, newSortBy);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(router.location.search);
    const urlSortBy = searchParams.get('sortBy') || 'createdAt';
    const initialFilters = {};

    searchParams.forEach((value, key) => {
      if (key !== 'sortBy' && key !=='via') initialFilters[key] = value;
    });

    // Prevent setting state if values haven't changed
    if (urlSortBy !== sortBy || JSON.stringify(initialFilters) !== JSON.stringify(filters)) {
      setSortBy(urlSortBy);
      setFilters(initialFilters);
    }
  }, [router.location.search]); // Dependencies ensure this runs only when URL search params change


  useEffect(() => {
    if (auth.user && !auth.user.questions){
      router.push('/onboarding')
    }

    if (router.query.paid) {
      if (auth.user?.planIsActive && !router.query.credit) {
        toast.success(`Congrats! You are now subscribed to Cleve 🥳`)
      }
      if (router.query.credit) {
        toast.success(`You successfully bought ${router.query.credit} credit!`)
      }
    }
  }, [auth]);



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box> {/* paddingTop should be equal or greater than the height of your tabs to prevent content from being hidden behind the sticky tabs */}
      <Meta title="Cleve" />
      <Box className={classes.container}>
      <TabPanel value={value} index={0}>
          <div onClick={() => router.push('/notes/new')} id="noteEditorContainer">
            <Tiptap currentNote={currentNote} setCurrentNote={setCurrentNote} buttonMode={true}/>
          </div>
          <Box style={{display: 'flex', justifyContent: 'flex-end', padding: '10px'}}>
            <SortMenu onSortChange={handleSortChange} selectedSortBy={sortBy} properties={sortProperties} />
            <FilterMenu properties={properties} values={values} onApplyFilter={handleApplyFilter} />
          </Box>
            {Object.entries(filters).map(([property, value], index) => (
              <Chip
                key={index}
                label={`${property}: ${value}`}
                onDelete={() => handleDeleteFilter(property)}
                color="primary"
                style={{ margin: '5px', textTransform: 'capitalize'}}
              />
            ))}
            <NoteList 
              currentNote={currentNote}
              setCurrentNote={setCurrentNote} 
              {...usePagedNotesByOwner(auth?.user?.id, filters, sortBy)}
            />
      </TabPanel>
      <TabPanel value={value} index={1}>
            "Loading"
      </TabPanel>
      </Box>
      <NoteNav/>
    </Box>
  );
}

export default requireAuth(NoteListPage);