import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";

function FAQPage(props) {
  document.title = "FAQ's Cleve";

  return (
    <>
      <Meta />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
    </>
  );
}

export default FAQPage;
