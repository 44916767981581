import React, { useState, useEffect, useRef } from 'react';
import { 
  Typography, 
  Container, 
  TextField, 
  Button, 
  Grid, 
  Card, 
  CardContent,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  CircularProgress,
  IconButton,
  Avatar
} from '@material-ui/core';
import { Instagram, Facebook, Twitter, LinkedIn, WhatsApp, Close as CloseIcon } from '@material-ui/icons';
import { TikTokIcon, ThreadsIcon } from '../util/icons';
import { apiRequest } from "../util/util";
import { getRoast } from "../util/db";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { Link, useRouter } from "./../util/router.js";
import html2canvas from 'html2canvas';
import LockIcon from '@material-ui/icons/Lock';
import { useAuth } from '../util/auth.js';
import analytics from '../util/analytics.js';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const CHARACTERS = {
  copycat: {
    name: "Copycat",
    description: "You hop on every trend so fast that you reek of FOMO. I guess you're not much for originality, but at least you're always in the loop and never behind the times.",
    color: "#98B5FF",
    accent: "#022787",
    image: "/roast/characters/copycat.png"
  },
  raging_rhino: {
    name: "Raging Rhino",
    description: "You've got strong opinions and can be loud about them. Some say you’re intense, but at least you keep things interesting, and no one's ever bored when you're around.",
    color: "#BDFFC3",
    accent: "#028720",
    image: "/roast/characters/raging_rhino.png"
  },
  wannabee: {
    name: "Wannabee",
    description: "Your life looks perfect... online, anyway. Sure, you're can be a try-hard, but you've mastered the art of making everything post-worthy. It's a talent at this point.",
    color: "#FFD394",
    accent: "#876202",
    image: "/roast/characters/wannabee.png"
  },
  attention_hog: {
    name: "Attention Hog",
    description: "You live for the likes and comments, and it's pretty clear you'll do whatever it takes to get them. But people DO notice you, so... congratulations?",
    color: "#C1C1C4",
    accent: "#525259",
    image: "/roast/characters/attention_hog.png"
  },
  couch_potato: {
    name: "Couch Potato",
    description: "You follow half the internet but barely post yourself. You may look like a stalker, but at least you're staying informed about everyone... it shows you care.",
    color: "#FFCCEB",
    accent: "#C30599",
    image: "/roast/characters/couch_potato.png"
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E6EFFF',
    padding: theme.spacing(0),
    maxWidth: '432px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  logo: {
    height: 30,
  },
  title: {
    color: '#0047AB',
    fontWeight: 'bold',
  },
  subtitle: {
    color: '#0047AB',
  },
  adjective: {
    color: '#000',
    fontWeight: 'bold',
  },
  hashtag: {
    color: '#0047AB',
    marginRight: theme.spacing(1),
  },
  card: {
    height: '100%',
  },
  gifBox: {
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gif: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  radarChart: {
    width: '100%',
    height: 200,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  tabsContainer: {
    marginBottom: theme.spacing(4),
  },
  tab: {
    minWidth: 'auto',
    padding: theme.spacing(1, 2),
  },
  tabIcon: {
    fontSize: 24,
  },
  button: {
    backgroundColor: '#333',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#555',
    },
  },
  fileInput: {
    display: 'none',
  },
  fileInputLabel: {
    marginTop: theme.spacing(2),
    display: 'block',
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: '0 auto',
  },
  shareButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  shareButton: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1),
    borderRadius: '50%',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  facebook: {
    backgroundColor: '#3b5998',
  },
  twitter: {
    backgroundColor: '#1da1f2',
  },
  linkedin: {
    backgroundColor: '#0077b5',
  },
  whatsapp: {
    backgroundColor: '#25D366',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  uploadedImage: {
    maxWidth: '100%',
    maxHeight: '300px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    position: 'relative',
    maxWidth: 'fit-content',
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  emoji: {
    marginRight: theme.spacing(1),
    fontSize: '1.5rem',
  },
  listItem: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  listItemIcon: {
    minWidth: '24px',
  },
  loadingContainer: {
    padding: theme.spacing(4),
    backgroundColor: '#FFF',
    borderRadius: theme.spacing(1),
    maxWidth: '432px',
    margin: '0 auto',
  },
  loadingStep: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  loadingIcon: {
    marginRight: theme.spacing(1),
  },
  loadingWarning: {
    marginTop: theme.spacing(2),
    color: theme.palette.warning.main,
  },
}));

// Add this function at the top of the file, outside of any component
const downloadAsImage = (elementRef, fileName) => {
  html2canvas(elementRef.current).then(canvas => {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = canvas.toDataURL();
    link.click();
  });
};

const RoastProfile = ({ data, username }) => {
  const classes = useStyles();
  const characterKey = data.character.toLowerCase().replace(/ /g, '_');
  const character = CHARACTERS[characterKey] || CHARACTERS.attention_hog;
  
  const profileRef = useRef(null);
  const [gifDataUrl, setGifDataUrl] = useState(null);

  const radarData = Object.entries(data.scores).map(([key, value]) => ({
    subject: key,
    A: value,
    fullMark: 10,
  }));

  const cardStyle = {
    boxShadow: `2px 3px 2px ${character.accent}`,
    backgroundColor: '#FFFFFF',
  };

  useEffect(() => {
    if (data.gif) {
      fetch(data.gif)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => setGifDataUrl(reader.result);
          reader.readAsDataURL(blob);
        })
        .catch(error => console.error("Error fetching GIF:", error));
    }
  }, [data.gif]);

  return (
    <>
    <Box ref={profileRef} style={{maxWidth: '432px', margin: '0 auto'}}>
      <Box className={classes.root} style={{ 
        backgroundColor: character.color, 
        padding: '16px', 
        aspectRatio: '9 / 16',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: '0 auto'
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <img src="/cleve-text-logo.webp" alt="Cleve.ai" style={{ height: '20px' }} />
          <Typography variant="subtitle1" style={{ color: character.accent, fontWeight: 'bold' }}>Roast</Typography>
        </Box>

        <Typography variant="body2" style={{ color: character.accent, marginBottom: '4px' }}>
          {data.username}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4" style={{ color: character.accent, fontWeight: 'bold', marginBottom: '8px' }}>
              {character.name}
            </Typography>
            <Typography variant="body2" style={{ color: character.accent, marginBottom: '8px', fontSize: '0.7rem' }}>
              {character.description}
            </Typography>
            <Box mb={1}>
              {/* {data.hashtags.map((hashtag, index) => ( */}
                <Typography component="span" variant="body2" style={{ marginRight: '8px', color: character.accent, fontSize: '0.55rem' }}>
                  {data.hashtags.join(' ')}
                </Typography>
              {/* ))} */}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <img 
                src={character.image} 
                alt={character.name} 
                style={{ 
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  borderRadius: '8px',
                  boxShadow: `2px 2px 6px ${character.accent}`,
                }} 
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ flexGrow: 1, marginTop: '16px' }}>
          <Grid item xs={6}>
            <Card style={{ ...cardStyle, height: '100%' }}>
              <CardContent style={{ padding: '12px' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: character.accent, textAlign: 'center' }}>Profile roast</Typography>
                <Typography variant="body2" style={{ fontSize: '0.6rem' }}>{data.overview}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} style={{justifyContent: 'space-between',  display: 'flex', paddingBottom: '0px'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card style={cardStyle}>
                  <CardContent style={{ padding: '12px' }}>
                    <Typography variant="subtitle2" gutterBottom style={{ color: character.accent, fontSize: '0.8rem', textAlign: 'center' }}>Your life in a gif: {data.adjective}</Typography>
                    <Box style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {gifDataUrl ? (
                        <img src={gifDataUrl} alt="Your life" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                      ) : (
                        <CircularProgress />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card style={cardStyle}>
                  <CardContent style={{padding: '6px', textAlign: 'center' }}>
                    <Typography variant="subtitle2" gutterBottom style={{ color: character.accent }}>Roast stats</Typography>
                    <ResponsiveContainer width="100%" height={100}>
                      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" tick={{ fontSize: '0.35rem' }} />
                        <PolarRadiusAxis angle={30} domain={[0, 10]} tick={{ fontSize: '0.3rem' }} />
                        <Radar name="Score" dataKey="A" stroke={character.accent} fill={character.accent} fillOpacity={0.6} />
                      </RadarChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ ...cardStyle, height: '100%' }}>
              <CardContent style={{ padding: '8px', textAlign: 'center', }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: character.accent }}>Toxic traits</Typography>
                <Typography variant="body2" style={{ fontSize: '0.7rem' }}>{data.traits.join(', ')}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ ...cardStyle, height: '100%'}}>
              <CardContent style={{ padding: '8px', textAlign: 'center' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: character.accent }}>You're a</Typography>
                <Typography variant="h6">5/10</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ ...cardStyle, height: '100%' }}>
              <CardContent style={{ padding: '8px', textAlign: 'center' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: character.accent }}>Emoji</Typography>
                <Typography variant="h5">{data.emojis.join('')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1} style={{ fontSize: '0.6rem', backgroundColor: character.accent, padding: '10px', margin: 0 }}>
        <Typography variant="body2" style={{ fontSize: '0.8rem', color: '#fff'}}><strong>🔥 roasted by ai</strong> - Get yours at cleve.ai/roastme</Typography>
      </Box>
    </Box>
    <Box display="flex" justifyContent="center" mt={2}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => downloadAsImage(profileRef, 'roasted-by-cleve-ai.png')}
      >
        Save as Image
      </Button>
    </Box>
    </>
  );
};

const BrandAnalysisProfile = ({ data, roastId, auth, username }) => {
  const classes = useStyles();
  const profileRef = useRef(null);
  const router = useRouter();
  const [gifDataUrl, setGifDataUrl] = useState(null);

  const radarData = Object.entries(data.scores).map(([key, value]) => ({
    subject: key,
    A: value,
    fullMark: 10,
  }));

  const isUnlocked = Boolean(auth.user);

  useEffect(() => {
    if (data.gif) {
      fetch(data.gif)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => setGifDataUrl(reader.result);
          reader.readAsDataURL(blob);
        })
        .catch(error => console.error("Error fetching GIF:", error));
    }
  }, [data.gif]);

  return (
    <>
    <Box ref={profileRef} style={{maxWidth: '432px', margin: '20px auto', position: 'relative'}}>
      <Box 
        className={classes.root} 
        style={{ 
          backgroundColor: '#FFD700', 
          padding: '16px', 
          aspectRatio: '9 / 16',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
          margin: '0 auto',
          filter: isUnlocked ? 'none' : 'blur(5px)', // Remove blur if unlocked
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <img src="/cleve-text-logo.webp" alt="Cleve.ai" style={{ height: '20px' }} />
          <Typography variant="subtitle1" style={{ color: '#0047AB', fontWeight: 'bold' }}>Profile Analysis</Typography>
        </Box>

        <Typography variant="body2" style={{ color: '#0047AB', marginBottom: '4px' }}>
          {data.username}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h4" style={{ color: '#0047AB', fontWeight: 'bold', marginBottom: '8px'}}>
              {data.character}
            </Typography>
            <Box mb={1}>
              {/* {data.hashtags.map((hashtag, index) => ( */}
                <Typography component="span" variant="body2" style={{ marginRight: '8px', color: '#0047AB', fontSize: '0.55rem' }}>
                  {data.hashtags.join(' ')}
                </Typography>
              {/* ))} */}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              {gifDataUrl ? (
                <img 
                  src={gifDataUrl} 
                  alt="Profile GIF" 
                  style={{ 
                    width: '120px', 
                    height: '120px', 
                    objectFit: 'cover', 
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }}
                />
              ) : (
                <Avatar 
                  alt="Profile" 
                  style={{ 
                    width: '120px', 
                    height: '120px', 
                    objectFit: 'cover', 
                    borderRadius: '100%', 
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                  }}
                >
                  {data?.username?.charAt(0) ?? 'A'}
                </Avatar>
              )}
            </Box> 
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ flexGrow: 1, marginTop: '16px' }}>
          <Grid item xs={6}>
            <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent style={{ padding: '12px' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB', textAlign: 'center' }}>Your Story</Typography>
                <Typography variant="body2" style={{ fontSize: '0.6rem' }}>{data.story}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent style={{ padding: '12px' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB', textAlign: 'center' }}>What people would love to hear you share</Typography>
                <Typography variant="body2" style={{ fontSize: '0.6rem' }}>
                  {data.content_strategy}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent style={{ padding: '12px' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB', textAlign: 'center' }}>Your audience</Typography>
                <Typography variant="body2" style={{ fontSize: '0.6rem' }}>
                  {data.audience.join(', ')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent style={{ padding: '12px' }}>
                {/* <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB', textAlign: 'center' }}>Personality Type</Typography>
                <ResponsiveContainer width="100%" height={100}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarData}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" tick={{ fontSize: 8 }} />
                    <PolarRadiusAxis angle={30} domain={[0, 10]} tick={{ fontSize: 8 }} />
                    <Radar name="Score" dataKey="A" stroke="#0047AB" fill="#0047AB" fillOpacity={0.6} />
                  </RadarChart>
                </ResponsiveContainer> */}
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB', textAlign: 'center' }}>Your elevator pitch</Typography>
                <Typography variant="body2" style={{ fontSize: '0.6rem' }}>
                  {data.elevator_pitch}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent style={{ padding: '8px', textAlign: 'center' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB' }}>Content pillars</Typography>
                <Typography variant="body2" style={{ fontSize: '0.7rem' }}>{data.content_pillars.join(', ')}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent style={{ padding: '8px', textAlign: 'center' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB' }}>Core values</Typography>
                <Typography variant="body2" style={{ fontSize: '0.7rem' }}>{data.core_values.join(', ')}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
              <CardContent style={{ padding: '8px', textAlign: 'center' }}>
                <Typography variant="subtitle2" gutterBottom style={{ color: '#0047AB' }}>Vibe & Tone</Typography>
                <Typography variant="body2" style={{ fontSize: '0.7rem' }}>{data.tone.join(', ')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      
      {/* Lock overlay - only show if not unlocked */}
      {!isUnlocked && (
        <Box 
          position="absolute" 
          top={0} 
          left={0} 
          right={0} 
          bottom={0} 
          display="flex" 
          flexDirection="column"
          justifyContent="center" 
          alignItems="center" 
          bgcolor="rgba(0,0,0,0.5)"
        >
          <LockIcon style={{ fontSize: 60, color: 'white' }} />
          <Typography variant="h6" style={{ color: 'white', marginTop: 16 }}>
            Unlock Brand Analysis
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            style={{ marginTop: 16 }}
            onClick={() => {
              router.push(`/auth/signup?next=/roastme/${roastId}`) 
            }}
          >
            Sign in to unlock
          </Button>
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mt={1} style={{ fontSize: '0.6rem', backgroundColor: '#0047AB', padding: '10px', margin: 0 }}>
        <Typography variant="body2" style={{ fontSize: '0.8rem', color: '#fff'}}><strong>Cleve Profile Analysis</strong> - Get yours at cleve.ai/roastme</Typography>
      </Box>
      
    </Box>
    <Box display="flex" justifyContent="center">
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => downloadAsImage(profileRef, 'brand-analysis.png')}
        disabled={!isUnlocked} // Enable the button if unlocked
      >
        {isUnlocked ? 'Save as Image' : 'Save as Image)'}
      </Button>
    </Box>
    </>
  );
};

const LinkedinFeedback = ({ data }) => {
  const classes = useStyles();
  const feedbackRef = useRef(null);

  return (
    <>
    <Box ref={feedbackRef} style={{maxWidth: '432px', margin: '20px auto'}}>
      <Box className={classes.root} style={{ 
        backgroundColor: '#a2d2eb', 
        padding: '16px', 
        aspectRatio: '9 / 16',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: '0 auto'
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <img src="/cleve-text-logo.webp" alt="Cleve.ai" style={{ height: '20px' }} />
          <Typography variant="subtitle1" style={{ color: '#0077B5', fontWeight: 'bold' }}>LinkedIn Feedback</Typography>
        </Box>

        <Grid container spacing={2} style={{ flexGrow: 1, marginTop: '16px' }}>
          {['headline', 'about', 'experience', 'education', 'posts'].map((section) => (
            <Grid item xs={12} key={section}>
              <Card style={{ height: '100%', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                <CardContent style={{ padding: '12px' }}>
                  <Typography variant="subtitle2" gutterBottom style={{ color: '#0077B5', textAlign: 'center', textTransform: 'capitalize' }}>
                    {section}
                  </Typography>
                  <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                    {data[section]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1} style={{ fontSize: '0.6rem', backgroundColor: '#FFFFFF', padding: '10px', margin: 0 }}>
        <Typography variant="body2" style={{ fontSize: '0.8rem', color: '#0077B5'}}>
          <strong>Cleve LinkedIn Analysis</strong> - Get yours at cleve.ai/roastme
        </Typography>
      </Box>
      
    </Box>
    <Box display="flex" justifyContent="center" mt={2}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => downloadAsImage(feedbackRef, 'linkedin-feedback.png')}
      >
        Save as Image
      </Button>
    </Box>
    </>
  );
};

// Add this new component after the existing components and before the RoastPage component
const LoadingAnimation = () => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const timer1 = setTimeout(() => setCurrentStep(1), 3000);
    const timer2 = setTimeout(() => setCurrentStep(2), 6000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  const getStepIcon = (step) => {
    if (step < currentStep) {
      return <CheckCircleIcon className={classes.loadingIcon} style={{ color: '#4CAF50' }} />;
    } else if (step === currentStep) {
      return <CircularProgress size={20} className={classes.loadingIcon} />;
    } else {
      return <FiberManualRecordIcon className={classes.loadingIcon} style={{ color: '#757575' }} />;
    }
  };

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingStep}>
        {getStepIcon(0)}
        <Typography variant="body2">Checking who you are</Typography>
      </Box>
      <Box className={classes.loadingStep}>
        {getStepIcon(1)}
        <Typography variant="body2">Reading your Posts</Typography>
      </Box>
      <Box className={classes.loadingStep}>
        {getStepIcon(2)}
        <Typography variant="body2">AI Agent Analyzing</Typography>
      </Box>
      <Typography variant="body2" className={classes.loadingWarning}>
        <span role="img" aria-label="warning">⚠️</span> High traffic now! You may have to wait up to 30 seconds...
      </Typography>
    </Box>
  );
};

const RoastPage = () => {
  const classes = useStyles();
  const [selectedPlatform, setSelectedPlatform] = useState(0);
  const [roastData, setRoastData] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const platforms = ['Linkedin', 'Instagram', 'Tiktok'];
  const router = useRouter();
  const auth = useAuth();

  useEffect(() => {
    if (router.query.id) {
      getRoast(router.query.id).then(roast => {
        if (roast) {
          setRoastData(roast);
          setSelectedPlatform(platforms.findIndex(p => p.toLowerCase() === roast.platform));
        }
      }).catch(error => {
        console.error("Error fetching roast:", error);
        // Handle error (e.g., show an error message to the user)
      });
    }
  }, [router.query.id]);

  const handlePlatformChange = (event, newValue) => {
    setSelectedPlatform(newValue);
    setUsername('');
    setUploadedImages([]);
  };

  const getTabIcon = (index) => {
    switch(index) {
      case 0: return <LinkedIn className={classes.tabIcon} />;
      case 1: return <Instagram className={classes.tabIcon} />;
      case 2: return <TikTokIcon className={classes.tabIcon} />;
      default: return null;
    }
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    Promise.all(files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }))
    .then(results => {
      setUploadedImages(prevImages => [...prevImages, ...results]);
    });
  };

  const handleDeleteImage = (index) => {
    setUploadedImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  const handleRoast = async () => {
    setIsLoading(true);
    try {
      const result = await apiRequest(`roast`, "POST", {
        platform: platforms[selectedPlatform].toLowerCase(),
        profileUrl: username,
        images: uploadedImages
      });
      analytics.track('roast', {
        platform: platforms[selectedPlatform].toLowerCase(),
        profileUrl: username,
      });
      console.log("result", result)
      setRoastData(result);
    } catch (error) {
      console.error("Error generating roast:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const renderArrayItems = (items) => {
    return (
      <List dense>
        {items.map((item, index) => (
          <ListItem key={index} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>•</ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    );
  };

  const shareUrl = "https://notes.cleve.ai"; // or use window.location.href if you want the current URL
  const shareTitle = "Check out my AI-generated roast!";

  const handleShare = (platform) => {
    let url;
    switch (platform) {
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'twitter':
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`;
        break;
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareTitle)}`;
        break;
      case 'whatsapp':
        url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareTitle + ' ' + shareUrl)}`;
        break;
      default:
        return;
    }
    window.open(url, '_blank');
  };

  const renderShareButtons = () => (
    <Box className={classes.shareButtons}>
      <IconButton className={`${classes.shareButton} ${classes.facebook}`} onClick={() => handleShare('facebook')}>
        <Facebook />
      </IconButton>
      <IconButton className={`${classes.shareButton} ${classes.twitter}`} onClick={() => handleShare('twitter')}>
        <Twitter />
      </IconButton>
      <IconButton className={`${classes.shareButton} ${classes.linkedin}`} onClick={() => handleShare('linkedin')}>
        <LinkedIn />
      </IconButton>
      <IconButton className={`${classes.shareButton} ${classes.whatsapp}`} onClick={() => handleShare('whatsapp')}>
        <WhatsApp />
      </IconButton>
    </Box>
  );

  return (
    <Box style={{ backgroundColor: '#FFF7E5' }}>
    <Container maxWidth="sm" style={{ backgroundColor: '#FFF7E5', padding: '0px' }}>
      <Box p={2}>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#333' }}>
          Roast my LinkedIn, Instagram, or TikTok Profile 🙀
        </Typography>
        <Typography variant="subtitle1" gutterBottom style={{ color: '#666' }}>
          Loads of people are getting their social media profiles brutally roasted.
          The real question is... is your ego ready for it?
        </Typography>
      </Box>

      <Box mb={4} p={2}>
        <Typography variant="h6" gutterBottom>
          Roast and profile analysis
        </Typography>
        <Tabs
          value={selectedPlatform}
          onChange={handlePlatformChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="platform tabs"
        >
          {platforms.map((platform, index) => (
            <Tab key={platform} icon={getTabIcon(index)} aria-label={platform} />
          ))}
        </Tabs>

        {/* Display uploaded images */}
        {uploadedImages.length > 0 && (
          <Grid container spacing={2} style={{ marginTop: '10px', textAlign: 'center' }}>
            {uploadedImages.map((image, index) => (
              <Grid item xs={6} sm={4} key={index}>
                <div className={classes.imageContainer}>
                  <img src={image} alt={`Uploaded ${index + 1}`} className={classes.uploadedImage} />
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => handleDeleteImage(index)}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Grid>
            ))}
          </Grid>
        )}
        
        {selectedPlatform === 0 ? ( // LinkedIn
          <Box display="flex" alignItems="center" mt={2}>
            <TextField 
              variant="outlined" 
              placeholder="Enter your LinkedIn profile URL"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleRoast}
              disabled={isLoading || !username}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Roast my profile 🔥'}
            </Button>
          </Box>
        ) : ( // Instagram or TikTok
          <Box mt={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <input
              accept="image/*"
              className={classes.fileInput}
              id="contained-button-file"
              type="file"
              onChange={handleImageUpload}
              multiple
            />
            <label htmlFor="contained-button-file">
              <Button variant="outlined" component="span" className={classes.button}>
                Upload Screenshots
              </Button>
            </label>
            <Button 
              variant="contained" 
              color="primary"
              onClick={handleRoast}
              disabled={isLoading || uploadedImages.length === 0}
              style={{ marginTop: '10px' }}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Roast my profile 🔥'}
            </Button>
          </Box>
        )}
        
        
      </Box>

        {isLoading ? (
          <LoadingAnimation />
        ) : roastData ? (
          <>
            <Typography gutterBottom variant="h5" style={{ fontWeight: 'bold', textAlign: 'center' }}>Part 1/2: Roast</Typography>
            <RoastProfile data={roastData.roast} username={username} />
            <Box mt={4} mb={4}></Box>
            {selectedPlatform === 0 && roastData.roast.linkedin_roast && (
              <>
                <LinkedinFeedback data={roastData.roast.linkedin_roast} />
                <Box mt={4} mb={4}></Box>
              </>
            )}
            <Typography gutterBottom variant="h5" style={{ fontWeight: 'bold', textAlign: 'center' }}>Part 2/2: Brand Analysis</Typography>
            <BrandAnalysisProfile data={roastData.brand_analysis} auth={auth} roastId={roastData.id} username={username} />
          </>
        ) : (
          <Box p={2} textAlign="center">
            <Box mb={2} textAlign="center">
              <img src="/roast/cat-roast.webp" alt="Cat roast" style={{ width: '300px' }} />
            </Box>
            
            <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Get your Free Personalised Roast Report in seconds!
            </Typography>
            <Typography variant="body1" gutterBottom style={{ textAlign: 'center' }}>
              Have your profile brutally roasted by AI and compare the results with your friends.
            </Typography>
            <Box mt={4} textAlign="center">
              <img src="/roast/report-samples.webp" alt="Report sample" style={{ width: '400px' }} />
            </Box>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginTop: '20px' }}>
              What is Cleve's AI Profile Roaster?
            </Typography>
            <Typography variant="body1" paragraph>
              Cleve AI Profile Roaster is an AI tool that looks at your social media profile and gives you a hilarious, slightly mean (but true) roast of your online persona. It's all in good fun, and you might even learn something about how others perceive you online!
            </Typography>

            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              How does it work?
            </Typography>
            <Typography variant="body1" component="div">
                1. Submit your profile link <br/>
                2. Our AI analyzes your profile <br/>
                3. Roast it with your friends <br/>
                4. Try again with a different social media profile <br/><br/>
            </Typography>

            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              Is it free?
            </Typography>
            <Typography variant="body1" paragraph>
              Yes, it's free! We're just having fun here.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              Can I roast my friends / colleagues?
            </Typography>
            <Typography variant="body1" paragraph>
              Absolutely! Just make sure they have a sense of humor. And remember to share their roast with them too!
            </Typography>

            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              Which platforms do you support?
            </Typography>
            <Typography variant="body1" paragraph>
              Currently we support LinkedIn, TikTok and Instagram.
            </Typography>

            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              Is my data safe?
            </Typography>
            <Typography variant="body1" paragraph>
              We only access publicly available data from your profile. We don't store any of your personal information, and your roast is deleted after 24 hours. Check out our privacy policy for more details!
            </Typography>

            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>
              How do I get started?
            </Typography>
            <Typography variant="body1" paragraph>
              Simply head to the Cleve AI Profile Roaster page, submit your profile link, and wait for the roast to begin!
            </Typography>
            </Box>
        )}
          <Box mt={4} p={2} style={{ backgroundColor: '#FFD700' }}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Cleve is more than just a Profile Roasting tool.
            </Typography>
            <Typography variant="body1" paragraph style={{ textAlign: 'center' }}>
              Use our personalized AI to create content for your socials.
            </Typography>
            <Box mb={2} textAlign="center">
              <img src="/roast/roast-cta.webp" alt="Roast CTA" style={{ width: '400px' }} />
            </Box>
            <Box textAlign="center" mb={4}>
              <Button variant="contained" component={Link} color="secondary" style={{ marginTop: '10px', borderRadius: '20px' }} to="/">
                Create your first post in &lt;2 mins
              </Button>
            </Box>
          </Box>
      

      
    </Container>
    </Box>
  );
};

export default RoastPage;