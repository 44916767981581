import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

function useDiscardDialog(isDirty) {
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const navigate = useCallback(() => {
    closeModal();
    if (lastLocation) history.push(lastLocation.pathname);
  }, [closeModal, lastLocation, history]);

  useEffect(() => {
    if (confirmedNavigation) {
      navigate();
    }
  }, [confirmedNavigation, navigate]);

  useEffect(() => {
    const unblock = history.block((location) => {
      //whitelist 'purchase' link
      if (isDirty && !confirmedNavigation && !location.pathname.includes('purchase')) {
        setDialogOpen(true);
        setLastLocation(location);
        return false; // Block navigation
      }

      return true; // Allow navigation
    });

    return () => {
      unblock();
    };
  }, [isDirty, confirmedNavigation, history]);

  // Confirm navigation and close dialog
  const confirmNavigation = useCallback(() => {
    localStorage.removeItem('content')
    setConfirmedNavigation(true);
  }, []);

  // Cancel navigation and close dialog
  const cancelNavigation = useCallback(() => {
    setConfirmedNavigation(false);
    closeModal();
  }, [closeModal]);

  return { dialogOpen, confirmNavigation, cancelNavigation };
}

export default useDiscardDialog