import React, { useEffect} from "react";
import Meta from "../components/Meta";
import ContactSection from "../components/ContactSection";
import { useRouter } from "../util/router";

function ContactPage(props) {
  document.title = "Contact Cleve - Get in Touch with Our Team";
  const router = useRouter();

  useEffect(() => {
    window.location.replace("https://wa.me/60109847954?text=Hi%2C+I%E2%80%99m+interested+in+exploring+Cleve+for+my+company")
    return () => {
      // Optional: Navigate back if the user navigates away from the external link
      router.history.goBack();
    };
  }, [router.history]);

  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
      />
    </>
  );
}

export default ContactPage;
