import React from "react";
import Container from "@material-ui/core/Container";
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useState } from "react";
import { Chip } from "@material-ui/core";
import { apiRequest } from "../util/util";
import PaypalSubscriptionButton from "./PaypalSubscriptionButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { redirectToBilling } from "./../util/stripe";
import FaqSection from "./FaqSection";
import TestimonialSection from "./TestimonalSection";
import ComparisonSection from "./ComparisonSection";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginX: 'auto',
  },
  contentBox: {
    display: 'flex',
    //backgroundColor: theme.palette.grey[900], // Example using a theme color
    //border: `1px solid ${theme.palette.grey[800]}`, // Example using a theme color
    //borderRadius: theme.shape.borderRadius,
    // maxWidth: { lg: '4xl', xl: 'none' },
    // marginY: theme.spacing(1.5),
    // gridTemplateColumns: { md: 'repeat(3, 1fr)' },
    gap: theme.spacing(1.25),
    // marginX: 'auto',
    padding: theme.spacing(1.5),
  },
  leftSide: {
    display: { xs: 'none', sm: 'none', md: 'block' },
    paddingTop: theme.spacing(1.5),
    paddingBottom: 0,
    paddingLeft: theme.spacing(1.5),
    maxWidth: '360px',
    width: '360px',
  },
  planBox: {
    background: theme.paper,
    flexWrap: 'wrap',
    //boxShadow: theme.shadows[1],
    borderRadius: '10px',
    padding: theme.spacing(3),
    //border: '1px solid',
    minWidth: '340px',
    display: 'flex',
    position: 'relative',
  },
  discountChip: {
    background: '#E95576',
  },
  priceText: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: 'pink',
  },
  perkList: {
    fontSize: 14,
  },
  cancelText: {
    fontSize: 16,
  },
  // Add more styles as needed
}));

function PricingSection(props) {
  const router = useRouter();
  const auth = useAuth();

  const classes = useStyles();

  const premiumBenefits = [
    "8 credits on registration"
  ];

  const plans = [
    {
      id: "free",
      name: "Free Plan",
      price: "$0",
      duration: "month",
      perks: [
        "8 free AI posts credits per month",
        "Unlimited notes",
        "Unlimited AI-tagging",
        "Unlimited AI questions",
        "Schedule posts internally"
      ],
      buttonText: "Try Cleve",
      buttonLink: "/"
    },
    {
      id: "plus",
      name: "Monthly Plan",
      price: "$29",
      // actualPrice: "$29",
      // discount: "0",
      duration: "month",
      perks: [
        "Unlimited AI generated posts",
        "Unlimited AI editing",
        "Unlimited notes",
        "Unlimited AI-tagging",
        "Unlimited AI questions",
        "Schedule posts internally",
        "Lifetime updates"
      ],
      buttonText: "Select",
      buttonLink: "/purchase/plus"
    },
    {
      id: "premium",
      name: "Annual Plan",
      price: "$20",
      actualPrice: "$29",
      discount: "30",
      duration: "month",
      perks: [
        "Save 30% when you opt for the annual plan",
        "Unlimited AI generated posts",
        "Unlimited AI editing",
        "Unlimited notes",
        "Unlimited AI-tagging",
        "Unlimited AI questions",
        "Schedule posts internally",
        "Lifetime updates"
      ],
      buttonText: "Select",
      buttonLink: "/purchase/premium"
    },
    {
      id: "enterprise",
      name: "Enterprise Plan",
      price: "Custom",
      perks: [
        "All Pro features",
        "Suitable for agencies and organisations",
        "Multi-account access",
        "Team features",
        "Dedicated account manager",
        "Custom requirements training",
        "Analytics and performance",
        "Advanced security features"
      ],
      buttonText: "Contact Sales",
      buttonLink: "/contact"
    },
  ];

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pending, setPending] = useState(false);
  const [selectedPlan, setselectedPlan] = useState(plans[2]);


  // To test the selected order for payment on button click
  const handleConsole = (plan) => {
    // console.log("your final placedd order",plan);
  }
  // To highlight the selected plan and to assign it to plan selected for payment
  const handleBoxClick = (plan) => {
    if (plan === "plus") {
      setselectedPlan(plans[0]);
      // console.log(selectedPlan)

    }
    if (plan === "premium") {
      setselectedPlan(plans[1]);

    }
    // console.log(selectedPlan)

  };

  const handleCancel = async () => {
    setPending(true)
    try {
      if (auth.user && auth.user.subscriptionProvider == 'stripe') {
        redirectToBilling().catch((error) => {
          setErrorMessage(error.message)
        });
        setPending(false)
      }
      else {
        console.log('before request')

        const response = await apiRequest("paypal-cancel-subscription", "POST", {
          subscriptionId: auth.user.paypalSubscriptionId
        });
        console.log('request sent')
        console.log(response)
        setPending(false)
        setMessage('Subscription cancelled')
      }


    } catch (error) {
      console.error(error);
      setErrorMessage(`Could not cancel paypal subscription...${error}`);
    }
  }


  const isBoxSelected = (boxName) => {
    // console.log("pane",boxName)
    return selectedPlan.id === boxName;
  };
  return (
    <>
      <Section>
        <Container>
          <Typography variant="h5" align="center" gutterBottom > Build your brand & thought leadership faster with AI that understands you </Typography>
          <Typography variant="body1" align="center" gutterBottom>
          Use Cleve Free Forever. Upgrade for unlimited AI posts, AI editing and more to supercharge your workflow.<br />
          </Typography>
          {auth.user && auth.user.planIsActive &&
            <Typography variant="body1" align="center" gutterBottom style={{ marginTop: '30px' }}>
              You are currently subscribed to Cleve. <br /><Link to="/settings/billing">View Subscription</Link>
            </Typography>
          }
        </Container>
      </Section>

      <Box className={classes.root}>
        <Box className={classes.contentBox}>
          <Box id="middle" className={classes.planBox}>
            {plans.map((plan) => (
              <Box
                key={plan.id}
                sx={{
                  margin: '10px',
                  background: isBoxSelected(plan.id) ? "#FFC92844" : "rgba(0,0,0,0)",
                  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                  borderRadius: "10px",
                  p: 3,
                  border: `1px solid ${isBoxSelected(plan.id) ? 'orange' : 'var(--Pop-Up-Stroke, #ccc)'
                    }`,
                  minWidth: 250,
                  maxWidth: 300,
                  position: "relative",
                }}
              >
                {plan.discount &&
                  <Box sx={{ position: "absolute", bottom: '0px', top: "-10px", left: "40px", zIndex: 'tooltip' }}>
                    <Chip label={`${plan.discount}% off`} style={{ background: '#E95576', color: 'white' }} size="small" />
                  </Box>
                }
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Typography style={{ color: 'text.primary', fontWeight: '800' }}>
                    {plan.name}
                  </Typography>
                </Box>
                <Box my={1} sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="h5" style={{ fontSize: '2.3rem', fontWeight: 'bold', gap: 2, color: '#E22B75' }}>
                    {plan.price}
                  </Typography>

                  {plan.duration &&
                    <Typography variant="body1" sx={{ fontSize: '0.875rem', fontWeight: 'medium', position: 'relative' }}>
                      {"/" + plan.duration}
                    </Typography>
                  }
                  {plan.actualPrice &&
                    <Typography variant="caption" style={{ color: "silver" }} sx={{ fontSize: '0.8rem', fontWeight: 'bold', gap: 2 }}>
                      <s> {" " + plan.actualPrice}</s>{"/" + plan.duration}
                    </Typography>
                  }


                </Box>
                {plan.buttonText &&
                  <Button
                    component={Link}
                    to={
                      auth.user
                        ? `${plan.buttonLink}`
                        : `/auth/signup?next=${plan.buttonLink}`
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={true}
                    disableElevation={true}
                    style={{ marginTop: '20px' }}
                  >
                    <strong>{plan.buttonText}</strong>
                  </Button>
                }
                <Box sx={{ fontSize: 14 }}>
                  {plan.perks.map((perk, index) => (
                    <div style={{marginTop: 20, maxWidth: '250px'}} key={index}>✔️ &nbsp;&nbsp;{perk}</div>
                  ))}
                </Box>

                
              </Box>
            ))}

            {/* {(auth.user && auth.user.planIsActive)
  ? 
  <div>
    <Button variant="contained" size='large' fullWidth={true} disabled> You already have {auth.user.planId == 'plus' ? 'Basic' : 'Premium'} plan </Button>
    <Button variant="text" color="primary" fullWidth={true} onClick={handleCancel}> 
       {!pending && <span>Cancel plan</span>} 
       {pending && <CircularProgress size={28} />}
    </Button>
  </div>
  :
  <div>
    <Button
      onClick={handleConsole(selectedPlan)}
      component={Link}
      to={
        auth.user
          ? `/purchase/${selectedPlan.id}`  // Use selectedPlan.id
          : `/auth/signup?next=/purchase/${selectedPlan.id}`  // Use selectedPlan.id
      }
      variant="contained"
      color="primary"
      size="large"
      fullWidth={true}
    >
      <strong>Subscribe</strong>
    </Button>
    <PaypalSubscriptionButton planId={selectedPlan.id} setMessage={setMessage} setErrorMessage={setErrorMessage}/>
    </div> */}

            {message && (
              <Box mx="auto" mb={4} maxWidth={400}>
                <Alert severity="success">
                  {message}
                  <span
                    role="img"
                    aria-label="party"
                    style={{ marginLeft: "10px" }}
                  >
                  </span>
                </Alert>
              </Box>
            )}

            {errorMessage && (
              <Box mx="auto" mb={4} maxWidth={400}>
                <Alert severity="error">
                  {errorMessage}
                  <span
                    role="img"
                    aria-label="party"
                    style={{ marginLeft: "10px" }}
                  >
                  </span>
                </Alert>
              </Box>
            )}


          </Box>

        </Box>
      </Box>
      <Typography variant="h4" align="center" gutterBottom style={{ marginTop: '30px' }}>
        FAQ
      </Typography>
      <FaqSection />
      <TestimonialSection />
      <ComparisonSection/>
    </>
  );
}

export default PricingSection;
