import React from 'react';
import { Grid, Card, CardContent, Typography, Avatar, Container, CardHeader } from '@material-ui/core';
import Section from './Section';
import SectionHeader from './SectionHeader';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  card: {
    maxWidth: 345, // Adjust based on your layout
    marginX: 'auto',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'rgba(100, 100, 100, 0.2) 0px 8px 24px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px'
  },
  avatar: {
    height: 48,
    width: 48,
    marginBottom: theme.spacing(2),
  }
}));

const testimonials = [
  { name: "Zoe Wee", role: "Marketing Specialist", content: "Just wanted to let you know I tried using Cleve to make a post today and it turned out to be my best performing (LinkedIn) post ever with 332 likes. Thanks so much for letting me try out the tool!", image: "https://framerusercontent.com/images/qUaGiA5F1JLDSWUvjYxRFYcCWA4.jpeg?scale-down-to=512" },
  { name: "Fion Chua", role: "Founder of PocketByte", content: "I used Cleve to help me draft my post and it helped cut down my creation time by half. Really helpful in mapping out my thoughts too!", image: "https://framerusercontent.com/images/LrvjC5E5giqaviZtpG2y0UDPwkA.jpeg?scale-down-to=512" },
  { name: "Nadra Qamaria", role: "Founder of The Nadra Agency", content: "I've been using Cleve for 90% of my content on Insta and LinkedIn. Love the 'Ask Me' button so much!! I'm quite introspective so it's kinda like asking the 5 whys in ideation :)", image: "https://framerusercontent.com/images/U4AFXHdj9oW6W8yWUR11Nrtseo.jpeg?scale-down-to=512"},
  { name: "Val Thien", role: "Small business owner", content: "I really like the idea that this is like a 'one stop creative space'. Could totally be the next notion but in social media marketing imo 😎", image: "https://framerusercontent.com/images/3xsAI3faNcoa1LCzLbiGLWRLPOI.jpeg?scale-down-to=512" },
  { name: "S. Wong", role: "Content Strategist", content: "Been trying out Cleve for my day job and it's been a big help with turning our messy client notes into quick content drafts. Also, the recent updates have been nailing the warm and personal tone and I'm here for it ✍️", image: "https://framerusercontent.com/images/OjpdnEE1HLGUSYHtxeLk7jCKnrU.jpeg" },
  { name: "Kimberley Chong", role: "UX designer & developer", content: "The LinkedIn post I wrote today was with the help of Cleve and it really is hitting more reach than all my previous posts", image: "https://framerusercontent.com/images/9p0g3BbpYDQG9ahiZCGXBUJJw.jpeg?scale-down-to=512" },
  { name: "Clarence Cheong", role: "Financial Services Director", content: "Cleve is awesome. I know because I'm on the Beta. Better y'all get onboard before they start charging its value in subscriptions [Based on the value, it's probably going to be high] 😏", image: "https://framerusercontent.com/images/ZKNijcuzwlSkPlMhhUAo3qIcHrg.jpeg" },
  { name: "Ario Limin", role: "LinkedIn Ghostwriter", content: "I recently found out about Cleve.ai and I love it. Cleve asks you questions one by one to help you narrow your thoughts. The best part about it is the content is originally yours since it's using your answers (not GPT generated)", image: "https://framerusercontent.com/images/rEeQG5wbI3G9ZFN1mCdm1LaPEc.jpeg?scale-down-to=512" },
  { name: "Yogicen", role: "Startup Generalist", content: "This is amazing! I just posted something out of Cleve and I personally absolutely love it!! will definitely use it more frequently in future", image: "https://framerusercontent.com/images/jjWkySxkJWXZjKjMOaPKlSGFLdg.jpeg?scale-down-to=512" },
];

const TestimonialSection = (props) => {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="lg">
        <SectionHeader
          title={props.title || "What our users are saying"}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          {testimonials.map((testimonial, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardHeader
                  style={{paddingBottom: 0}}
                  avatar={<Avatar src={testimonial.image} className={classes.avatar}>{testimonial.name[0]}</Avatar>}
                  title={testimonial.name}
                  titleTypographyProps={{ style: { fontWeight: 'bold', fontSize: "1.1rem" } }}
                  subheaderTypographyProps={{ style: { fontSize: "0.8rem" } }}
                  subheader={testimonial.role}
                />
                <CardContent>
                  <Typography variant="body2" color="textPrimary" component="p" style={{fontSize: "1rem"}}>
                    {testimonial.content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default TestimonialSection;