import React, { useState, useEffect, useMemo } from 'react';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Highlight, RefinementList, Configure, useInstantSearch } from 'react-instantsearch';
import { Typography, Card, CardContent, Chip, makeStyles } from '@material-ui/core';
import 'instantsearch.css/themes/satellite.css';
import NoteCard from './NoteCard';
import { useAuth } from '../util/auth';
import { apiRequest } from '../util/util';



const useStyles = makeStyles((theme) => ({
  '@global': {
    '.ais-SearchBox-input':{
      boxShadow: 'none',
      borderRadius: '20px',
      backgroundColor: theme.palette.background.default
    },
    '.ais-SearchBox-input:focus':{
      borderColor: theme.palette.primary.main
    },
    '.ais-SearchBox-form':{
      backgroundColor: theme.palette.background.default
    },
    '.ais-Hits-item':{
      padding: '0.2rem'
    }
  },
  root: {
    padding: theme.spacing(1),
  },
  searchBox: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
  },
  sidebar: {
    width: '30%',
    paddingRight: theme.spacing(2),
  },
  main: {
    width: '70%',
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function EmptyQueryBoundary({ children, fallback }) {
  const { indexUiState } = useInstantSearch();

  if (!indexUiState.query) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

function NoResultsBoundary({ children }) {
  const { results } = useInstantSearch();

  if (!results.__isArtificial && results.nbHits === 0) {
    return <Typography style={{paddingLeft: '10px'}} variant="body1">No results found</Typography>;
  }

  return children;
}

const NoteHit = ({ hit }) => {
  const highlightedContent = hit._highlightResult?.content?.value || hit.content;
  const unescapedContent = highlightedContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&#39;/g, "'");
  const contentWithMarks = unescapedContent.replace(/<em>/g, '<mark>').replace(/<\/em>/g, '</mark>');
  return (
    <NoteCard
      note={{
        id: hit.objectID,
        content: contentWithMarks,
        createdAt: { seconds: new Date(hit.createdAt).getTime() / 1000 },
        status: hit.status,
        tags: hit.tags,
        // Add any other properties that NoteCard expects
      }}
      setCurrentNote={() => {}} // Provide a no-op function or handle as needed
    />
  );
};

const NoteSearch = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [algoliaKey, setAlgoliaKey] = useState(null);

  useEffect(() => {
    
    const fetchAlgoliaKey = async () => {
      if (auth.user && auth.user.algoliaKey) {
        setAlgoliaKey(auth.user.algoliaKey);
      } else {
        try {
          //only fetch algolia secured key if it doesn't exist on user doc
          const response = await apiRequest('algolia-key');
          if (response.algoliaKey) {
            setAlgoliaKey(response.algoliaKey);
          } else {
            console.error('Failed to fetch Algolia key:');
          }
        } catch (error) {
          console.error('Error fetching Algolia key:', error);
        }
      }
    };

    if (auth.user) {
      fetchAlgoliaKey();
    }
  }, [auth.user]);

  const searchClient = useMemo(() => {
    if (!algoliaKey) {
      return null;
    } else {
      const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, algoliaKey)

      // algolia client wrapper to add additional logic for empty query
      // send formatted response if it's empty
      const proxyClient = {
        ...algoliaClient,
        search(requests) {
          if (requests.every(({ params }) => !params.query)) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: '',
                params: '',
              })),
            });
          }

          return algoliaClient.search(requests);
        },
      };

      return proxyClient;
    }
  }, [algoliaKey]);
  
  return searchClient ? (
    <InstantSearch searchClient={searchClient} indexName="notes">
      <div className={classes.root}>
        <SearchBox className={classes.searchBox} />
        <div className={classes.content}>
          <EmptyQueryBoundary fallback={null}>
            <NoResultsBoundary>
              <Hits hitComponent={NoteHit} />
            </NoResultsBoundary>
          </EmptyQueryBoundary>
        </div>
      </div>
    </InstantSearch>
  ) : null;
};

export default NoteSearch;
