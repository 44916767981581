import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { POST_TYPE, LANGUAGES } from '../util/enums';
import { useAuth } from '../util/auth';
import Box from '@material-ui/core/Box';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiAccordionSummary-content.Mui-expanded':{
      margin: 0,
    }
  },
  accordion: {
    border: 'none',
    minWidth: '270px',
    // width: '270px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },

  switchRoot: {
    width: 45,
    height: 26,
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(1)
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.background.default,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
  },
  switchThumb: {
    width: 24,
    height: 24,
  },
  switchTrack: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.type === 'dark'? theme.palette.background.default : theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  switchChecked: {},
  switchFocusVisible: {},

  marginZero: {
    margin: 0
  },

  label: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 5,
  },

  customCTA: {
    marginTop: '8px', marginBottom: '16px', backgroundColor: theme.palette.background.default
  },

  platformButton: {
    width: '100%',
    fontWeight: 'bold',
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
    justifyContent: 'flex-start',
    textAlign: 'left',
    borderRadius: theme.spacing(1),
    border: '1px solid #E0E0E0',
    backgroundColor: 'transparent',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.main + '70',
      borderColor: theme.palette.primary.main,
    },
  },
  modalTitle: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  modalSubtitle: {
    padding: theme.spacing(0, 2),
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  imageReferenceBox: {
    padding: theme.spacing(1.5),
    // display: 'flex',
    // alignItems: 'center',
    fontSize: 9,
    justifyContent: 'space-between',
  },
}));

// const ControlledAutocomplete = ({ 
//   options = [], 
//   renderInput, 
//   getOptionLabel, 
//   control, 
//   defaultValue, 
//   name, 
//   renderOption,
//   getOptionSelected,
//   multiple = false
// }) => {
//   return (
//     <Controller
//       render={({ onChange, ...props }) => (
//         <Autocomplete
//           multiple={multiple}
//           options={options}
//           getOptionLabel={getOptionLabel}
//           getOptionSelected={getOptionSelected}
//           renderOption={renderOption}
//           renderInput={renderInput}
//           disableCloseOnSelect
//           onChange={(event, value) => onChange(value)}
//           {...props}
//         />
//       )}
//       //onChange={([, data]) => (data.map(data.title))} // Ensure this handler correctly interprets the data format
//       defaultValue={defaultValue}
//       name={name}
//       control={control}
//     />
//   );
// };

const RepurposeModal = ({ open, onClose, handleGenerate, hasImages }) => {
  // State for managing checkbox states
  const classes = useStyles();
  const auth = useAuth();
  const theme = useTheme();
  const [platforms, setPlatforms] = useState({
    [POST_TYPE.linkedin.id]: {checked: true, label: POST_TYPE.linkedin.label},
    [POST_TYPE.instagram.id]: {checked: false, label: POST_TYPE.instagram.label},
    [POST_TYPE.script.id]: {checked: false, label: POST_TYPE.script.label},
    [POST_TYPE.tweet.id]: {checked: false, label: POST_TYPE.tweet.label},
    [POST_TYPE.thread.id]: {checked: false, label: POST_TYPE.thread.label},
    [POST_TYPE.newsletter.id]: {checked: false, label: POST_TYPE.newsletter.label}
  });

  // Add new state for image reference
  const [useImages, setUseImages] = useState(true);

  const [options, setOptions] = useState({
    emojis: {checked: false, label: "Emojis", description: "Include emojis in your content"},
    paragraphs: {checked: true, label: "Paragraphs", description: "Toggle on for short paragraphs, toggle off for one-liners"},
    hashtags: {checked: false, label: "Hashtags", description: "Include hashtags in your content"},
    cta: {checked: false, label: "CTA", description: "Include a CTA in your content"},
  });

  const [customCTA, setCustomCTA] = useState('');
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    setLanguage(auth?.user?.language || {name: "English", code: "en", native: "English"});
  }, [auth?.user?.language]);

  const handleCustomCTAChange = (event) => {
    setCustomCTA(event.target.value);
  };

  const handleChange = (event) => {
    setPlatforms({ ...platforms, [event.target.name]: {checked: event.target.checked, label: platforms[event.target.name].label}});
  };

  const handleOptionsChange = (event) => {
    setOptions({ ...options, 
      [event.target.name]: {checked: event.target.checked, 
      label: <>
          <Typography>{options[event.target.name].label}</Typography>
          <Typography variant="body2" style={{fontSize: 12}}>{options[event.target.name].description}</Typography>
      </>
    }});
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm"
      fullWidth
    >
      <Typography className={classes.modalTitle}>
        Generate
      </Typography>

      <DialogContent style={{flexDirection: 'column', display:'flex'}}>
        {Object.keys(platforms).map((platform) => (
          <Button
            key={platform}
            className={`${classes.platformButton} ${platforms[platform].checked ? 'selected' : ''}`}
            onClick={() => setPlatforms({
              ...platforms,
              [platform]: {
                ...platforms[platform],
                checked: !platforms[platform].checked
              }
            })}
          >
            {platforms[platform].label}
          </Button>
        ))}

          <Box className={classes.imageReferenceBox}>
            <FormControlLabel
              value="top"
              size="small"
              control={
                <Checkbox
                  color="default"
                  size='small'
                  checked={useImages}
                  onChange={(e) => setUseImages(e.target.checked)}
                  name="useImages"
                />
              }
              label="Refer image content"
              labelPlacement="end"
            />
          </Box>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="customization-panel-content"
            id="customization-panel-header"
          >
            <Typography style={{fontSize: 16, fontWeight: 'bold'}}>Advanced Customisation</Typography>
          </AccordionSummary>
          <AccordionDetails style={{flexDirection: 'column'}}>
            {Object.keys(options).map((option) => (
              <FormControlLabel
                key={option}
                labelPlacement="start" 
                control={
                  <Switch 
                    classes={{
                      root: classes.switchRoot,
                      switchBase: classes.switchBase,
                      thumb: classes.switchThumb,
                      track: classes.switchTrack,
                      checked: classes.switchChecked,
                    }}
                    color="primary" 
                    checked={options[option].checked} 
                    onChange={handleOptionsChange} 
                    name={option}
                  />
                }
                className={classes.label}
                label={
                  <Box>
                    <Typography>{options[option].label}</Typography>
                    <Typography variant="body2" color="textSecondary" style={{fontSize: 12}}>
                      {options[option].description}
                    </Typography>
                  </Box>
                }
              />
            ))}

            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              label="Custom CTA"
              disabled={!options.cta.checked}
              value={customCTA}
              onChange={handleCustomCTAChange}
              className={classes.customCTA}
              placeholder="Enter your custom call-to-action here..."
            />

            <Autocomplete
              name="language"
              options={LANGUAGES}
              onChange={(event, value) => setLanguage(value)}
              getOptionLabel={(option) => `${option.name} - ${option.native}`}
              getOptionSelected={(option, value) => option.code === value.code}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Language" placeholder="Select Language" />
              )}
              defaultValue={auth?.user?.language || {name: "English", code: "en", native: "English"}}
              renderOption={(option, {selected}) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                    style={{ marginRight: 8 }}
                  />
                  {`${option.name} - ${option.native}`}
                </React.Fragment>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions style={{padding: theme.spacing(2)}}>
        <Button 
          onClick={() => handleGenerate(platforms, {
            toggles: options,
            customCTA,
            language,
            useImages
          })}
          color="primary"
          variant="contained"
          fullWidth
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RepurposeModal;