import { Box, Typography,List,ListItem} from "@material-ui/core";
import React from "react";

function LegalTerms(props) {
    document.title = "Terms of Service for Cleve";

  return (
    <Box my={2}sx={{mt:"2px"}}>
       
    <Box
      sx={{
        width: 'inherit',
        display: 'flex',
        px:"2px",
        mb:"1.6rem",
        
        backgroundColor:"rgba(0, 0, 0, 0)",
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h4"gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Terms of service
      </Typography>
      <Typography
gutterBottom
        variant="body2"
        sx={{
          color: 'grey.500',
          fontWeight: 'medium',
          textAlign: 'right', // Align text to the right

          
        }}
      >
        Effective Date: 28/03/2024
      </Typography>

      <h2><strong>Introduction</strong></h2>
      <p>Welcome to Cleve, your AI-powered content assistant. These Terms of Service ("Terms") govern your use of Cleve's services and platforms. By accessing or using Cleve, you agree to be bound by these Terms and our Privacy Policy.</p>

      <h2><strong>1. Acceptance of Terms</strong></h2>
      <p>By using Cleve, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree, you may not use Cleve's services.</p>

      <h2><strong>2. Eligibility and Account Registration</strong></h2>
      <p><strong>2.1 Eligibility:</strong> The Service is intended for users who are at least 18 years old. Minors must have their parent or guardian's consent to use the Service.</p>
      <p><strong>2.2 Account Registration:</strong> To access certain features, you must create an account with accurate and complete information. You are responsible for maintaining the confidentiality of your account and password.</p>

      <h2><strong>3. Use of Service</strong></h2>
      <p><strong>3.1 Permitted Use:</strong> Cleve grants you a limited, non-exclusive, non-transferable, and revocable license to use the Service for personal and professional content creation.</p>
      <p><strong>3.2 Restrictions:</strong> You agree not to misuse the Service in any manner that violates these Terms, including but not limited to unauthorized access, distribution of harmful or illegal content, or interference with the Service's operation.</p>

      <h2><strong>4. User Content and Intellectual Property</strong></h2>
      <p><strong>4.1 Ownership and Rights:</strong> You retain ownership of your content. You grant Cleve a license to use your content for service provision.</p>
      <p><strong>4.2 License to Cleve:</strong> A non-exclusive, worldwide, royalty-free license to use, modify, and distribute your content for the purpose of operating Cleve.</p>
      <p><strong>4.3 User Responsibilities:</strong> You are responsible for the content you create and agree not to violate laws or third-party rights.</p>

      <h2><strong>5. Subscription Terms and Cancellation Policy</strong></h2>
      <p><strong>5.1 Subscription Types:</strong> Cleve offers monthly and annual subscriptions. Access to premium features is contingent upon subscription.</p>
      <p><strong>5.2 Cancellation Policy:</strong> Monthly subscriptions can be canceled at any time, with annual subscriptions bound by the term's duration.</p>
      <p><strong>5.3 Refunds and Exceptions:</strong> Payments are generally non-refundable, except under exceptional circumstances at Cleve's discretion.</p>

      <h2><strong>6. Data Privacy and Security</strong></h2>
      <p>Cleve is committed to protecting your privacy and ensuring the security of your data. We do not share your data with third parties, except as necessary for service functionality.</p>

      <h2><strong>7. Termination</strong></h2>
      <p>Cleve may terminate your access to the Service for violations of these Terms, without prior notice or liability.</p>

      <h2><strong>8. Disclaimers and Limitations of Liability</strong></h2>
      <p>Cleve is provided "as is." We do not guarantee uninterrupted access or error-free service. Liability is limited to the maximum extent permitted by law.</p>

      <h2><strong>9. Governing Law</strong></h2>
      <p>These Terms are governed by Malaysian law. Any disputes will be subject to the exclusive jurisdiction of the courts of Malaysia.</p>

      <h2><strong>10. Changes to Terms</strong></h2>
      <p>Cleve reserves the right to modify these Terms. Continued use of the Service after changes constitutes agreement to the new Terms.</p>

      <h2><strong>11. Contact Information</strong></h2>
      <p>For questions or concerns regarding these Terms, please contact us at support@cleve.ai.</p>

    </Box>

    </Box>
  );
}

export default LegalTerms;
