import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Grid, Paper, Typography, InputBase, Chip, Tooltip, IconButton,
  Card, CardHeader, CardContent, CardActions, Avatar, CircularProgress, ButtonGroup,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Link
} from '@material-ui/core';
import { PlayArrowOutlined, FavoriteBorder, OpenInNew, Loop, BookmarkBorder, CommentOutlined } from '@material-ui/icons';

import ThumbUp from '@material-ui/icons/ThumbUpOutlined';
import SearchIcon from '@material-ui/icons/Search';
import InfoIcon from '@material-ui/icons/Info';
import { useAuth } from '../util/auth.js';
import { useRouter } from '../util/router.js';
import { useCompletion } from "ai/react";
import * as Sentry from '@sentry/react';
import { Masonry } from "react-plock";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import analytics from '../util/analytics.js';
import { apiRequest } from "../util/util.js";
import { textToHtmlParagraphs } from '../util/util.js';
import Skeleton from '@material-ui/lab/Skeleton';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

const sampleN = (arr, n) => [...arr].sort(() => 0.5 - Math.random()).slice(0, n);

function removeDuplicateThumbnails(questions) {
  const seenArticles = new Set();
  console.log('Processing duplicate')

  let inspirations = questions
  if(questions.questions){
    inspirations = questions.questions
  }

  return inspirations.map(item => {
    if (item.news) {
      const articleId = item.news.articleId;
      console.log(articleId)
      if (seenArticles.has(articleId)) {
        console.log('Duplciate detected')
        // This is a duplicate, nullify the image_url
        return {
          ...item,
          news: {
            ...item.news,
            imageUrl: null
          }
        };
      } else {
        // This is the first occurrence, add to seen set
        seenArticles.add(articleId);
        console.log('Adding ', articleId)
        return item;
      }
    }
    // If there's no news, return the item as is
    return item;
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
    // backgroundColor: th,
  },
  searchBar: {
    // marginLeft: '50px',
    // marginRight: '50px',
    padding: '2px 8px',
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchIcon: {
    padding: 3,
  },
  categoriesContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
  },
  cardContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: theme.spacing(2),
    minWidth: 280,
    borderRadius: '10px',
    backgroundColor: theme.palette.background.paper,
  },
  cardIcon: {
    color: '#2583E8',
    fontSize: '18px',
    marginRight: '6px'
  },
  newsCard: {
    backgroundColor: theme.palette.type === 'light'? '#EAF4FF' : '#0e122e',
    paddingTop: theme.spacing(4)
  },
  newsLabel:{
    color: '#2583E8',
    borderColor: '#2583E8',
    borderRadius: '5px',
    fontSize: '11px',
  },
  infoButton: {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    
  },
  newsInfoIcon: {
    color: '#2583E8',
    padding: '2px'
  },
  personalCard: {
    backgroundColor: '#E8B724'
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'contain',
  },
  
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    marginBottom: theme.spacing(2),
  },
}));

const cardStyles = [
  { bgcolor: '#ffffff', color: '#000000', fontSize: '23px', fontFamily: 'Montserrat' },
  { bgcolor: '#ededed', color: '#000000', fontSize: '18px', fontFamily: 'Montserrat' },
  { bgcolor: '#fffaea', color: '#2d2d2d', fontSize: '31px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#ffffff', color: '#2d2d2d', fontSize: '19px', fontFamily: '"Courier New", monospace' },
  { bgcolor: '#eaf4ff', color: '#2d2d2d', fontSize: '35px', fontFamily: '"Caveat", cursive' },
  { bgcolor: '#e8eaf6', color: '#2d2d2d', fontSize: '21px', fontFamily: '"Montserrat", sans-serif' },
];

const nl2br = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  // Split by '. ', '! ', and '? ', but keep the delimiters
  // Exclude splits where the period is preceded by a number (e.g., "1. ", "2. ")
  return str.split(/(?<=[a-zA-Z]\. |\! |\? )/).map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br></br>
      <br></br>
      {/* {index !== str.split('\n').length - 1 && <br />} */}
      {/* {index !== str.split('\n').length - 1 && <br />} */}
    </React.Fragment>
  ));
};

const LinkedinCard = ({post, onClick }) => {
  const classes = useStyles()
  const [showImage, setShowImage] = useState(true);

  const avatar_url = post?.influencer?.avatar_url
  const name = post?.influencer?.name
  const profile_url = post?.influencer?.profile_url

  const reactions = ['applause_count', 'support_count', 'love_count', 'interest_count', 'laugh_count']
  let reactions_count = 0

  for (const reaction of reactions) {
    reactions_count += post?.[reaction] ?? 0
  }

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth < 200 || naturalHeight < 200) {
      setShowImage(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (post &&
    <Card elevation={0} className={classes.card} >
      <CardHeader
        avatar={
          <Avatar src={avatar_url} className={classes.avatar}>
            {!avatar_url && name && name.charAt(0)}
          </Avatar>
        }
        action={
          <Tooltip title="View post" >
            <IconButton 
              component={Link}
              href={post.external_linked_in_post_url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open post"
            >
              <OpenInNew style={{opacity: 0.5, fontSize: '18px'}} />
            </IconButton>
          </Tooltip>
        }
        title={name}
        subheader={formatDate(post?.created_at)}
        // subheaderTypographyProps={{ style: {fontSize: '12px'} }}
      />
      <CardContent className={classes.content} onClick={onClick}>
        <Typography variant="body" color="textPrimary" component="p" 
          dangerouslySetInnerHTML={{__html: textToHtmlParagraphs(post?.text ?? '')}}>
        </Typography>
          {post.media_urls &&
          <img src={post.media_urls[0]} alt="Post image" className={classes.cardImage} onLoad={handleImageLoad} onError={() => setShowImage(false)} />
          }
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="likes">
        <img
            style={{ height: '18px', marginRight: '6px' }}
            src="/li-reactions.svg"
            alt="reactions-icon"
          />
          <Typography variant="body2" color="textSecondary">
          {reactions_count}
        </Typography>
        </IconButton>
        <div style={{marginLeft: 'auto'}}>
          <Typography variant="body2" color="textSecondary">
            {post.comments_count} comments • {post.reposts_count} reposts
          </Typography>
        </div>
        
      </CardActions>
    </Card>
  );
};

const TiktokCard = ({post, onClick }) => {
  const classes = useStyles()
  const [showImage, setShowImage] = useState(true);

  const avatar_url = post?.author?.avatar
  const name = post?.author?.nickname

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth < 200 || naturalHeight < 200) {
      setShowImage(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  


  return (post?.transcript && post.transcript.length > 30 &&
    <Card elevation={0} className={classes.card} >
      <CardHeader
        avatar={
          <Avatar src={avatar_url} className={classes.avatar}>
            {!avatar_url && name && name.charAt(0)}
          </Avatar>
        }
        action={
          <Tooltip title="View post" >
            <IconButton 
              component={Link}
              href={`https://www.tiktok.com/@${post.author?.unique_id}/video/${post.video_id}`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Open post"
            >
              <OpenInNew style={{opacity: 0.5, fontSize: '18px'}} />
            </IconButton>
          </Tooltip>
        }
        title={name}
        subheader={'@' + post.author?.unique_id}
        // subheaderTypographyProps={{ style: {fontSize: '12px'} }}
      />
      <CardContent className={classes.content} onClick={onClick}>
        <Typography variant="body" color="textPrimary" component="p" 
          dangerouslySetInnerHTML={{__html: textToHtmlParagraphs(post.content)}}>
        </Typography>
          {
          <img src={post.cover} alt="Post image" className={classes.cardImage} onLoad={handleImageLoad} onError={() => setShowImage(false)} />
          }
      </CardContent>
      <CardActions disableSpacing>
        {
          [
            {icon: PlayArrowOutlined, count: post.play_count},
            {icon: FavoriteBorder, count: post.digg_count},
            {icon: CommentOutlined, count: post.comment_count},
            {icon: BookmarkBorder, count: post.collect_count},
            {icon: Loop, count: post.share_count},
          ].map((item) => (
            <IconButton aria-label="likes">
              <item.icon className={classes.cardIcon} />
              <Typography variant="body2" color="textSecondary">
                
              {
              // round to nearest thousand
              item.count > 1000 ? Math.round(item.count/1000) + 'k' : item.count}
            </Typography>
            </IconButton>
          ))
        }
      </CardActions>
    </Card>
  );
};

const TwitterCard = ({  avatar_url, name, handle, date, content, likes, shares, url, onClick }) => {
  const classes = useStyles()
  const [showImage, setShowImage] = useState(true);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (naturalWidth < 200 || naturalHeight < 200) {
      setShowImage(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Card elevation={0} className={classes.card} >
      <CardHeader
        avatar={
          <Avatar src={avatar_url} className={classes.avatar}>
            {!avatar_url && name && name.charAt(0)}
          </Avatar>
        }
        title={name}
        action={
          <Tooltip title="View post" >
            <IconButton 
              component={Link}
              href={url}
              target="_blank"
              color="inherit"
              rel="noopener noreferrer"
              aria-label="Open post"
            >
              <OpenInNew style={{opacity: 0.5, fontSize: '18px'}} />
            </IconButton>
          </Tooltip>
        }
        subheader={handle}
        subheaderTypographyProps={{ style: {fontSize: '12px'} }}
      />
      <CardContent className={classes.content} onClick={onClick}>
        <Typography variant="body" color="textPrimary"
          dangerouslySetInnerHTML={{__html: textToHtmlParagraphs(content ?? '')}}
        >
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="likes">
          <FavoriteBorder className={classes.cardIcon} />
          <Typography variant="body2" color="textSecondary">
          {likes}
        </Typography>
        </IconButton>
        <IconButton aria-label="shares">
          <Loop className={classes.cardIcon} />
          <Typography variant="body2" color="textSecondary">
          {shares}
        </Typography>
        </IconButton>
        
      </CardActions>
    </Card>
  );
};



const InspirationDashboard = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSearchTerm, setActiveSearchTerm] = useState('Personal Branding');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [postType, setPostType] = useState('twitter');
  console.log(activeSearchTerm)

  const queryClient = useQueryClient();

  const { data: generatedIdeas = [], isFetching, error } = useQuery({
    queryKey: ['inspirations', postType, activeSearchTerm],
    queryFn: () => fetchIdeas(activeSearchTerm, auth?.user?.auth.currentUser.getIdToken()),
    enabled:  !!auth?.user,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: 24000 * 60 * 60, // Cache for 24 hour
    initialData: () => {
      const cachedData = queryClient.getQueryData(['inspirations', postType, activeSearchTerm]);
      return cachedData || undefined;
    },
  });

  const generateIdeasMutation = useMutation({
    mutationFn: (keyword) => fetchIdeas(keyword, auth?.user?.auth.currentUser.getIdToken()),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['inspirations', postType, variables], data);
      setActiveSearchTerm(variables);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${postType}`, variables);
      }
    },
  });

  const fetchIdeas = async (keyword, token) => {
    console.log("IT'S BEING CALLED ", keyword)
    const IdToken = await token
    const response = await fetch('/api/inspiration', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${IdToken}`,
      },
      body: JSON.stringify({ 'type': postType, 'keyword': keyword }),
    });
    if (!response.ok) {
      throw new Error('Failed to fetch inspirations');
    }
  
    const data = await response.json();
    return data;
  }

  const handleCategoryToggle = (category) => {
    analytics.track('clickIdeaCategory', {category: category})
    setSearchTerm(category);
    const cachedData = queryClient.getQueryData(['inspirations', postType, category]);
    if (!cachedData) {
      generateIdeasMutation.mutate(category);
    } else {
      setActiveSearchTerm(category);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${postType}`, category);
      }
    }
  };

  const handleIdeaClick = (idea, author) => {
    const source = author ?? 'Unknown'
    analytics.track('clickInspiration', {idea: idea, postType: postType})
    let text = `
    <p>Inspired by this post:</p>
    <blockquote>
    ${textToHtmlParagraphs(idea)}
    <span>- ${source}</span>
    </blockquote>
    <p></p>
    `

    // let text = `
    // <p>Inspired by ${source}'s post:</p>
    // ${textToHtmlParagraphs('"'+idea+'"')}
    // <p></p>
    // `
    router.push(`/notes/new`, { note: { content: text, id: null } });
  };

  const handleSearchSubmit = (event) => {
    if (event) event.preventDefault();
    analytics.track('searchIdea', {searchTerm})
    if (generateIdeasMutation.isLoading) return;
    const cachedData = queryClient.getQueryData(['inspirations', postType, searchTerm]);
    if (!cachedData) {
      generateIdeasMutation.mutate(searchTerm);
    } else {
      setActiveSearchTerm(searchTerm);
      if (typeof window !== 'undefined') {
        localStorage.setItem(`lastActiveSearchTerm_${postType}`, searchTerm);
      }
    }
  };

  const handleRefresh = () => {
    if (activeSearchTerm) {
      generateIdeasMutation.mutate(activeSearchTerm);
    }
  };

  useEffect(() => {
    let _categories ={
      //'linkedin': ['sales', 'startup', 'women', 'work', 'career', 'tech', 'marketing', 'money', 'productivity', 'founder'],
      'linkedin': ['Personal Branding', 'Copywriting', 'Founders', 'Productivity', 'ChatGPT'],
      'twitter': ['coding','peace','thread','sales','viral','startup','fitness','nutrition','self-improvement', 'product-launch','relationships','money','online-money','work','milestone','marketing','founder','saas'],
      'tiktok': []
    }
    setCategories(shuffleArray(_categories[postType]).slice(0, 8))
    generatedIdeas.length < 1 && generateIdeasMutation.mutate(activeSearchTerm)
  }, [postType]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={classes.root}>
    
      <div className={classes.categoriesContainer}>
        <div>
        {auth?.user && categories.map((category) => (
          <Chip
            key={category}
            label={category}
            disabled={generateIdeasMutation.isLoading}
            onClick={() => handleCategoryToggle(category)}
            color={category === activeSearchTerm ? "primary" : "default"}
            className={classes.chip}
          />
        ))}
        </div>
        <ButtonGroup style={{marginTop: '10px'}}>
          {
          ['twitter', 'linkedin', 'tiktok'].map((type) => (
            <Button 
              size="small"
              onClick={() => {setPostType(type); generateIdeasMutation.mutate(activeSearchTerm)}}
              style={{opacity: postType === type? 1: 0.75, textTransform: 'capitalize'}}
              color={postType === type? 'secondary': 'default'}
              variant={postType === type? 'contained': 'outlined'}>
              {type}
            </Button>
          ))
        }
      </ButtonGroup>
      </div>
      
      {generatedIdeas.length < 1 && 
        <div className={classes.cardContainer}>
           {new Array(9).fill(0).map((_, index) => (
          <Skeleton variant="rect" width={'25%'} height={200} className={classes.card} style={{backgroundColor: '#ddd'}}/>
           ))}
        </div>
      }
      <Grid container spacing={4} className={classes.cardContainer}>
        {auth?.user && (
          <Masonry
            config={{
              columns: [1, 2, 3],
              gap: [1, 12, 6],
              media: [640, 768, 1024],
            }}
            items={generatedIdeas ?? []}
            render={(item, index) => {
              if (postType === 'linkedin') {
                return <LinkedinCard
                  {...item?.props}
                  onClick={() => handleIdeaClick(item?.props?.post?.text, item?.props?.post?.influencer?.name)}
                />
              }
              else if (postType === 'twitter') {
                return <TwitterCard
                  {...item}
                  onClick={() => handleIdeaClick(item?.content, item?.handle)}
                />
              }
              else if (postType === 'tiktok') {
                item.content  = item?.transcript.replace(/([.!?])\s+/g, '$1\n')
                return <TiktokCard
                  post = {item}
                  onClick={() => handleIdeaClick(item.content, item?.author?.nickname)}
                />
              }
            }}
          />
        )}
      </Grid>
      
    </div>
  );
};

export default InspirationDashboard;