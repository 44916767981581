import React, { useEffect } from "react";
import Meta from "./../components/Meta";
import AuthSection from "./../components/AuthSection";
import { useRouter } from "./../util/router";
import { toast } from "react-toastify";

function AuthPage(props) {
  const router = useRouter();

  useEffect(() => {
    if (router.query.message === "emailVerified") {
      toast.success("Your email has been verified.", {autoClose: false});
    }
  }, [router.query.type]);

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={["google"]}
        afterAuthPath={router.query.next || "/"}
      />
    </>
  );
}

export default AuthPage;
