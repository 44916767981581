import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const CleveLoading = ({ height = '40px', message = 'Loading...' }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <img 
        src="/logo.svg" 
        alt="Loading..." 
        style={{ height, margin: '20px' }}
        className='ld ld-spin'
      />
      <Typography style={{animationDuration: '1.5s'}} className='ld ld-dim'>
        {message}
      </Typography>
    </Box>
  );
};

CleveLoading.propTypes = {
  height: PropTypes.string,
};

export default CleveLoading;